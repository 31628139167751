/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import useEmblaCarousel from 'embla-carousel-react';
import { toast } from 'react-toastify';
import { publicApi } from '../../util';
import Loader from '../../PalmSchool/components/Loader';
import styles from './css/Deal.module.scss';
import ProductPage from './ProductPage';
import { chooseProduct } from '../../redux/reducer/productAction';

const OPTIONS = { align: 'start', loop: true };

const options = {
  theme: 'light',
  position: 'bottom-right',
  autoClose: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const Deal = () => {
  const [deals, setDeals] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [emblaRef] = useEmblaCarousel(OPTIONS);
  const dispatch = useDispatch();

  const selectedProduct = useSelector((state) => state.product.selectedProduct);

  const handleSelectProduct = (product) => {
    dispatch(chooseProduct(product, 'deal'));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const updateProjectCount = () => {
      if (showAll) {
        setProducts(deals);
      } else {
        setProducts(deals.slice(0, 5));
      }
    };
    updateProjectCount();

    window.addEventListener('resize', updateProjectCount);

    return () => {
      window.removeEventListener('resize', updateProjectCount);
    };
  }, [deals, showAll]);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const res = await publicApi.get('/products/deal/all');
        setDeals(res.data);
        setLoading(false);
      } catch (error) {
        toast.error('Having some network troubles', options);
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  if (loading || !deals) {
    return <Loader loading={loading} />;
  }

  return (
    <motion.div className={styles.slider}>
      <article className={styles.header}>
        <h4 className={styles.title}>Hot Deals</h4>
        <button type="button" className={styles.seeMoreBtn} onClick={toggleShowAll}>
          {!showAll ? 'See all' : 'See less'}
        </button>
      </article>
      {!showAll && (
        <div className={styles.viewport} ref={emblaRef}>
          <div className={styles.emblaContainer}>
            {products && products.map((product) => (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                whileTap={{ scale: 0.95 }}
                key={`key-${product._id}`}
                className={styles.slide}
                onClick={() => handleSelectProduct(product)}
              >
                <motion.img src={product.productImage} alt={product.title} className={styles.img} />
                <div className={styles.productDetails}>
                  <h5 className={styles.productName}>{product.title}</h5>
                  <p className={styles.productMetric}>
                    {product.locationStreet}
                    <span className={styles.productCity}>
                      {` (${product.locationCity})`}
                    </span>
                  </p>
                </div>
                <p className={styles.productPrice}>
                  {`₦${product.price.toLocaleString()}/${product.time}`}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      )}
      {showAll && (
        <div className={styles.allProducts}>
          {deals && deals.map((product) => (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              whileTap={{ scale: 0.95 }}
              key={product._id}
              className={styles.slide}
              onClick={() => handleSelectProduct(product)}
            >
              <div className={styles.productImg}>
                <motion.img src={product.productImage} alt={product.title} className={styles.img} />
              </div>
              <div className={styles.productDetails}>
                <h3 className={styles.productName}>{product.title}</h3>
                <p className={styles.productMetric}>
                  {product.locationStreet}
                  <span className={styles.productCity}>
                    {` (${product.locationCity})`}
                  </span>
                </p>
              </div>
              <p className={styles.productPrice}>
                {`₦${product.price.toLocaleString()}/${product.time}`}
              </p>
            </motion.div>
          ))}
        </div>
      )}
      {selectedProduct && selectedProduct.source === 'deal' && (
        <ProductPage product={selectedProduct} deal />
      )}
    </motion.div>
  );
};

export default Deal;
