/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  MdStar,
  MdOutlineAccessAlarms,
  MdOutlinePriceChange,
  MdOutlineSubtitles,
  MdStarBorder,
} from 'react-icons/md';
import { CiGlobe } from 'react-icons/ci';
import { BsBarChart } from 'react-icons/bs';
import { toast } from 'react-toastify';
import NavB from '../../../Nav/NavB';
import styles from './css/Search.module.scss';
import Loader from '../../components/Loader';
import { publicApi, auth } from '../../../util';

const useQuery = () => new URLSearchParams(useLocation().search);

const options = {
  theme: 'light',
  position: 'bottom-right',
  autoClose: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const Search = () => {
  const navigate = useNavigate();
  const [allCourses, setAllCourses] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [isOpen, setIsOpen] = useState(null);
  const query = useQuery().get('query') || '';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchAvailableCourses = async () => {
      try {
        setIsLoading(true);
        const response = await publicApi.get(`/courses/search?query=${query}`);
        if (response.status !== 200) {
          toast.error('Check your internet connection and try again', options);
          throw new Error('Failed to fetch course');
        }

        setAllCourses(response.data);
        setIsLoading(false);
      } catch (error) {
        toast.error(error.message, options);
        setIsLoading(false);
      }
    };

    if (query) fetchAvailableCourses();
  }, [query]);

  const proceedToCourse = async (courseId) => {
    try {
      setIsLoading(true);
      const response = await auth.post('/courses/enroll', { courseId });
      if (response.status !== 200) {
        toast.error(response.data.message, options);
      } else {
        toast.success(response.data.message, options);
        navigate(`/palmschool/courses/${courseId}`);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error.message);
    }
  };

  const exitProjectInfo = () => setIsOpen(!isOpen);

  const handleClick = (course) => {
    setIsOpen(!isOpen);
    setSelectedCourse(course);
  };

  if (isLoading) return <Loader loading={isLoading} />;

  return (
    <>
      <NavB palmschool palmtrack={false} palmstore={false} palmvest={false} />
      <section className={styles.container}>
        <div className={styles.div}>
          <h1 className={styles.title}>
            Search Result for &quot;
            {query}
            &quot;
          </h1>
        </div>
        <div className={styles.courses}>
          {allCourses?.length === 0 && (
          <p className={styles.empty}>
            No
            {' '}
            &quot;
            {query }
            &quot;
            {' '}
            course was found. Try searching for a similar course
          </p>
          )}
          {allCourses && (
          <>
            <h3 className={styles.courseTitle}>
              Related Courses
              {` (${allCourses.length})`}
            </h3>
            <div className={styles.courseDiv}>
              {
              allCourses.map((course) => (
                <div
                  key={course._id}
                  className={styles.course}
                  onClick={() => handleClick(course)}
                >
                  <h2 className={styles.courseId}>{course.id}</h2>
                  <img src={course.image} className={styles.img} alt={course.name} />
                  <h5 className={styles.courseName}>{course.title}</h5>
                  <p className={styles.courseStar}>
                    {Array(5).fill(0).map((_, index) => ((
                      index < Math.round(course.ratingCount)) ? (
                        <MdStar
                          key={index}
                          className={styles.filledStar}
                        />
                      ) : (
                        <MdStarBorder
                          key={index}
                          className={styles.lightStar}
                        />
                      )))}
                  </p>
                </div>
              ))
            }
            </div>
          </>
          )}
        </div>
        {
          isOpen && (
            <div className={styles.courseDetails}>
              {
                selectedCourse && (
                  <div key={selectedCourse._id} className={styles.courseDetail}>
                    <h2 className={styles.courseDetailId}>{selectedCourse.id}</h2>
                    <img
                      src={selectedCourse.image}
                      className={styles.img}
                      alt={selectedCourse.course}
                    />
                    <h4 className={styles.courseName}>{selectedCourse.title}</h4>
                    <p className={styles.courseDesc}>{selectedCourse.description}</p>
                    <div className={styles.courseInfo}>
                      <p className={styles.courseMode}>
                        <CiGlobe className={styles.courseLabel} />
                        {selectedCourse.mode}
                      </p>
                      <p className={styles.courseMode}>
                        <BsBarChart className={styles.courseLabel} />
                        {selectedCourse.level}
                      </p>
                      <p className={styles.courseMode}>
                        <MdOutlineAccessAlarms className={styles.courseLabel} />
                        {selectedCourse.duration}
                      </p>
                      <p className={styles.courseMode}>
                        <MdOutlineSubtitles className={styles.courseLabel} />
                        English Subtitle
                      </p>
                      <p className={styles.courseMode}>
                        <MdOutlinePriceChange className={styles.courseLabel} />
                        {selectedCourse.price}
                      </p>
                    </div>
                    <div className={styles.actionDiv}>
                      <button
                        type="button"
                        className={`${styles.exit} ${styles.btn}`}
                        onClick={exitProjectInfo}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        onClick={() => proceedToCourse(selectedCourse._id)}
                        className={`${styles.continue} ${styles.btn}`}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                )
              }
            </div>
          )
        }
      </section>
    </>
  );
};

export default Search;
