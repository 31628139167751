/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth from './Auth';
import UnAuth from './UnAuth';
import { signOut, fetchStoreProducts } from '../redux/reducer/authActions';
// import { fetchOrdersReq } from '../redux/reducer/orderAction';

const Layout = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);

  const validateTokenExpiration = () => {
    if (token) {
      const tokenExpiration = JSON.parse(atob(token.split('.')[1])).exp * 1000;
      const isExpired = Date.now() > tokenExpiration;
      if (isExpired) {
        dispatch(signOut());
        window.location.href = '/login';
      }
    }
  };

  useEffect(() => {
    dispatch(fetchStoreProducts());
  }, [dispatch]);

  useEffect(() => {
    if (isAuth) {
      validateTokenExpiration();
    }
    const intervalId = setInterval(validateTokenExpiration, 60000);
    return () => clearInterval(intervalId);
  }, [isAuth, token, dispatch]);

  // useEffect(() => {
  //   if (isAuth) {
  //     fetchOrdersReq(dispatch);
  //   }
  // }, [isAuth, dispatch]);

  return (
    <BrowserRouter>
      <ToastContainer theme="colored" position="bottom-right" />
      {isAuth
        ? <Auth />
        : <UnAuth />}
    </BrowserRouter>
  );
};

export default Layout;
