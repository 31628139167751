/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Farmn from './Farm';
import Harvest from './Harvest';
import styles from './css/RenderForm.module.scss';

const RenderForm = ({ stock }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isOnFarm, setIsOnFarm] = useState(false);
  const [isHarvested, setIsHarvested] = useState(true);

  const handleFarm = () => {
    setIsOnFarm(true);
    setIsHarvested(false);
  };

  const handleHarvest = () => {
    setIsOnFarm(false);
    setIsHarvested(true);
  };

  return (
    <section className={`${styles.formDiv} ${stock && styles.myStock}`}>
      <div className={styles.flipper}>
        <label htmlFor="onFarm" className={styles.option}>
          <input type="radio" id="onFarm" className={styles.input} name="option" checked={isOnFarm} onChange={handleFarm} />
          <span className={styles.checkmark} />
          On Farm
        </label>
        <label htmlFor="onFarm" className={styles.option}>
          <input type="radio" id="harvested" className={styles.input} name="option" checked={isHarvested} onChange={handleHarvest} />
          <span className={styles.checkmark} />
          Harvested
        </label>
      </div>
      {isOnFarm && <Farmn />}
      {isHarvested && <Harvest />}
    </section>
  );
};

RenderForm.propTypes = {
  stock: PropTypes.bool.isRequired,
};

export default RenderForm;
