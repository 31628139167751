/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './Faqs.module.scss';
import FAQ from './faqsAPI';

const Faqs = () => {
  useEffect(() => {
    AOS.init({
      duration: 400,
      easing: 'ease-in',
      once: true,
    });
  }, []);

  const [isOpen, setIsOpen] = useState(null);

  const handleToggle = (index) => {
    setIsOpen((prevOpenIndex) => (prevOpenIndex === index ? null : index));
  };

  return (
    <section id="faqs" className={styles.faqContainer} style={{ overflow: 'hidden' }}>
      <h3 className={styles.faqHeader}>
        Frequently Asked Questions
      </h3>
      <div className={styles.faqContent}>
        {
          FAQ.map((item) => (
            <article className={styles.faq} key={item.id} data-aos="fade-up">
              <h4 className={styles.faqTitle} onClick={() => handleToggle(item.id)}>
                {item.question}
                {isOpen === item.id
                  ? <FaMinus className={styles.fliper} />
                  : <FaPlus className={styles.flip} />}
              </h4>
              { isOpen === item.id && <p className={styles.faqAnswer} data-aos="fade-right">{item.answer}</p> }
            </article>
          ))
        }
      </div>
    </section>
  );
};

export default Faqs;
