/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  MdStar,
  MdOutlineAccessAlarms,
  MdOutlinePriceChange,
  MdOutlineSubtitles,
  MdStarBorder,
} from 'react-icons/md';
import { CiGlobe } from 'react-icons/ci';
import { BsBarChart } from 'react-icons/bs';
import { toast } from 'react-toastify';
import NavB from '../../../Nav/NavB';
import VideoPlayer from '../../components/VideoPlayer';
import styles from './css/WelcomePage.module.scss';
import Loader from '../../components/Loader';
import { publicApi, auth } from '../../../util';

const options = {
  theme: 'light',
  position: 'bottom-right',
  autoClose: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const WelcomePage = () => {
  const navigate = useNavigate();
  const [allCourses, setAllCourses] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [isOpen, setIsOpen] = useState(null);

  const fetchAvailableCourses = async () => {
    try {
      setIsLoading(true);
      const response = await publicApi.get('/courses');

      if (response.status !== 200) {
        toast.error('Check your internet connection and try again', options);
        throw new Error('Failed to fetch doctor');
      }

      setAllCourses(response.data);
      setIsLoading(false);
    } catch (error) {
      toast.error(error.message, options);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAvailableCourses();
  }, []);

  const proceedToCourse = async (courseId) => {
    try {
      setIsLoading(true);
      const response = await auth.post('/courses/enroll', { courseId });
      if (response.status !== 200) {
        toast.error(response.data.message, options);
      } else {
        if (response.data.message === 'Enrolled successfully') toast.success(response.data.message, options);
        navigate(`/palmschool/courses/${courseId}`);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error.message);
    }
  };

  const exitProjectInfo = () => setIsOpen(!isOpen);

  const handleClick = (course) => {
    setIsOpen(!isOpen);
    setSelectedCourse(course);
  };

  return (
    <>
      <NavB palmschool palmtrack={false} palmstore={false} palmvest={false} />
      <motion.section className={styles.container}>
        <div className={styles.div}>
          <h1 className={styles.title}>Welcome to PalmSchool</h1>
          <p className={styles.subtitle}>Watch the introductory video below!</p>
        </div>
        <VideoPlayer />
        <div className={styles.courses}>
          <h3 className={styles.courseTitle}>Available Courses</h3>
          <div className={styles.courseDiv}>
            {isLoading && <Loader loading={isLoading} />}
            {
              allCourses && allCourses.map((course) => (
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.3 }}
                  whileTap={{ scale: 0.9, opacity: 0.8 }}
                  key={course._id}
                  className={styles.course}
                  onClick={() => handleClick(course)}
                >
                  <h2 className={styles.courseId}>{course.id}</h2>
                  <img src={course.image} className={styles.img} alt={course.name} />
                  <h5 className={styles.courseName}>{course.title}</h5>
                  <p className={styles.courseStar}>
                    {Array(5).fill(0).map((_, index) => ((
                      index < Math.round(course.ratingCount)) ? (
                        <MdStar
                          key={index}
                          className={styles.filledStar}
                        />
                      ) : (
                        <MdStarBorder
                          key={index}
                          className={styles.lightStar}
                        />
                      )))}
                  </p>
                </motion.div>
              ))
            }
          </div>
        </div>
        {
          isOpen && (
            <div className={styles.courseDetails}>
              {
                selectedCourse && (
                  <div key={selectedCourse._id} className={styles.courseDetail}>
                    <h2 className={styles.courseDetailId}>{selectedCourse.id}</h2>
                    <img
                      src={selectedCourse.image}
                      className={styles.img}
                      alt={selectedCourse.course}
                    />
                    <h4 className={styles.courseName}>{selectedCourse.title}</h4>
                    <p className={styles.courseDesc}>{selectedCourse.description}</p>
                    <div className={styles.courseInfo}>
                      <p className={styles.courseMode}>
                        <CiGlobe className={styles.courseLabel} />
                        {selectedCourse.mode}
                      </p>
                      <p className={styles.courseMode}>
                        <BsBarChart className={styles.courseLabel} />
                        {selectedCourse.level}
                      </p>
                      <p className={styles.courseMode}>
                        <MdOutlineAccessAlarms className={styles.courseLabel} />
                        {selectedCourse.duration}
                      </p>
                      <p className={styles.courseMode}>
                        <MdOutlineSubtitles className={styles.courseLabel} />
                        English Subtitle
                      </p>
                      <p className={styles.courseMode}>
                        <MdOutlinePriceChange className={styles.courseLabel} />
                        {selectedCourse.price}
                      </p>
                    </div>
                    <div className={styles.actionDiv}>
                      <button
                        type="button"
                        className={`${styles.exit} ${styles.btn}`}
                        onClick={exitProjectInfo}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        onClick={() => proceedToCourse(selectedCourse._id)}
                        className={`${styles.continue} ${styles.btn}`}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                )
              }
            </div>
          )
        }
      </motion.section>
    </>
  );
};

export default WelcomePage;
