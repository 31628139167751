/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import states from '../../../PalmTrack/assets/states.json';
import Success from '../../components/Success';
import Loader from '../../../PalmSchool/components/Loader';
import styles from './Form.module.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { auth } from '../../../util';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const validationSchema = Yup.object().shape({
  sale: Yup.bool().test(
    'leaseOrSaleSelected',
    'At least one of Sale or Lease must be selected',
    (value, { sale, lease }) => value || sale || lease,
  ),
  lease: Yup.bool().test(
    'leaseOrSaleSelected',
    'At least one of Sale or Lease must be selected',
    (value, { sale, lease }) => value || sale || lease,
  ),
  equipment: Yup.string().required('Equipment type is required'),
  otherEquipment: Yup.string()
    .when('equipment', {
      is: (val) => typeof val === 'string' && val.includes('others'),
      then: () => Yup.string().required('Please specify the equipment type')
        .min(5, 'It is too short'),
      otherwise: () => Yup.string().notRequired(),
    }),
  description: Yup.string(),
  address: Yup.string().required('Address is required'),
  state: Yup.string().required('State is required'),
  lga: Yup.string().required('LGA is required'),
  salesPrice: Yup.number().when('sale', {
    is: true,
    then: () => Yup.number().required('Sales price is required')
      .positive('Sales price must be a positive number'),
    otherwise: () => Yup.number().notRequired(),
  }),
  leasePrice: Yup.number().when('lease', {
    is: true,
    then: () => Yup.number().required('Lease price is required').positive('Lease price must be a positive number'),
    otherwise: () => Yup.number().notRequired(),
  }),
  duration: Yup.string().when('lease', {
    is: true,
    then: () => Yup.string().required('Lease duration is required'),
    otherwise: () => Yup.string().notRequired(),
  }),
});

const options = {
  theme: 'light',
  position: 'top-right',
  autoClose: 2000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const Equipment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [arrowToggle, setArrowToggle] = useState({
    equipment: false,
    state: false,
    lga: false,
    duration: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: {
      sale: false,
      lease: false,
      equipment: '',
      otherEquipment: '',
      description: '',
      address: '',
      state: '',
      lga: '',
      salesPrice: '',
      leasePrice: '',
      duration: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const data = await auth.post('trackequipment/new', { ...values });
        if (data.status === 201 || data.status === 200) {
          setIsLoading(false);
          setSuccess(true);
          formik.resetForm();
        }
      } catch (error) {
        setIsLoading(false);
        toast.error('Check your form or internet', options);
      } finally {
        setIsLoading(false);
      }
      setIsLoading(false);
    },
  });

  const handleArrowToggle = (field) => {
    setArrowToggle((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleEquipmentChange = (event) => {
    const { value } = event.target;
    formik.handleChange(event);
    setIsOtherSelected(value === 'others');
  };

  const geocoderContainerRef = useRef(null);
  const geocoderRef = useRef(null);

  useEffect(() => {
    if (geocoderContainerRef.current && !geocoderRef.current) {
      geocoderRef.current = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        placeholder: 'Enter address',
        types: 'address, country, region, place, postcode, locality, neighborhood',
      });

      geocoderRef.current.addTo(geocoderContainerRef.current);

      geocoderRef.current.on('result', (event) => {
        const { result } = event;
        formik.setFieldValue('address', result.place_name);
      });
    }
  }, []);

  return (
    <>
      <motion.form
        initial={{ opacity: 0, x: '-100%' }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: '100%' }}
        transition={{ delay: 0.5 }}
        onSubmit={formik.handleSubmit}
        className={styles.container}
      >
        <div className={styles.groupA}>
          <p className={styles.label}>Select as appropriate</p>
          <div className={styles.formGroupB}>
            <label htmlFor="sale" className={styles.formCombo}>
              <input
                type="checkbox"
                name="sale"
                onChange={formik.handleChange}
                checked={formik.values.sale}
                onBlur={formik.handleBlur}
                value
                className={styles.inputB}
              />
              Sale
              <span className={styles.checkmarkB} />
            </label>
            <label htmlFor="lease" className={styles.formCombo}>
              <input
                type="checkbox"
                name="lease"
                checked={formik.values.lease}
                onChange={formik.handleChange}
                value
                className={styles.inputB}
              />
              Lease
              <span className={styles.checkmarkB} />
            </label>
            {formik.errors.lease && formik.touched.lease && <div className={styles.error}>{formik.errors.lease}</div>}
          </div>
          <div className={styles.formGroup}>
            <select
              name="equipment"
              onChange={handleEquipmentChange}
              onBlur={formik.handleBlur}
              onClick={() => handleArrowToggle('equipment')}
              value={formik.values.equipment}
            >
              <option value="" label="Type of equipment" />
              <option value="tractor" label="Tractor" />
              <option value="sprayer" label="Sprayer" />
              <option value="disc_plough" label="Disc Plough" />
              <option value="lorry" label="Lorry" />
              <option value="oil_mill" label="Oil Mill" />
              <option value="others" label="Others" />
            </select>
            {!arrowToggle.equipment ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
            {formik.errors.equipment && formik.touched.equipment && <div className={styles.error}>{formik.errors.equipment}</div>}
          </div>

          {isOtherSelected && (
          <div className={styles.formGroup}>
            <input
              type="text"
              name="otherEquipment"
              placeholder="Specify the equipment type"
              onChange={formik.handleChange}
              value={formik.values.otherEquipment}
              onBlur={formik.handleBlur}
              className={styles.input}
            />
            {formik.errors.otherEquipment && formik.touched.otherEquipment && (
              <div className={styles.error}>{formik.errors.otherEquipment}</div>
            )}
          </div>
          )}

          <div className={styles.formGroup}>
            <input
              type="text"
              name="description"
              onChange={formik.handleChange}
              placeholder="Brief description"
              value={formik.values.description}
            />
            {formik.errors.description && formik.touched.description && <div className={styles.error}>{formik.errors.description}</div>}
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="address">Location</label>
            <div ref={geocoderContainerRef} className={styles.geocoderContainer} />
            {formik.errors.address && formik.touched.address && <div className={styles.error}>{formik.errors.address}</div>}
          </div>
          <div className={styles.formSplit}>
            <div className={styles.formGroup}>
              <select
                name="state"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onClick={() => handleArrowToggle('state')}
                value={formik.values.state}
              >
                <option value="" label="State" />
                {states.map((state) => <option key={state.state} value={state.state} label={state.state} />)}
              </select>
              {!arrowToggle.state ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
              {formik.errors.state && formik.touched.state && <div className={styles.error}>{formik.errors.state}</div>}
            </div>

            <div className={styles.formGroup}>
              <select
                name="lga"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onClick={() => handleArrowToggle('lga')}
                value={formik.values.lga}
              >
                <option value="" label="LGA" />
                {states.map((state) => (state.state === formik.values.state) && state.lgas.map((lga) => <option key={lga} value={lga} label={lga} />))}
              </select>
              {!arrowToggle.lga ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
              {formik.errors.lga && formik.touched.lga && <div className={styles.error}>{formik.errors.lga}</div>}
            </div>
          </div>
          <div className={styles.formGroup}>
            <input
              type="text"
              name="salesPrice"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Sales Price"
              value={formik.values.salesPrice}
            />
            {formik.errors.salesPrice && formik.touchedsalesPrice && <div className={styles.error}>{formik.errors.salesPrice}</div>}
          </div>
          <div className={styles.formSplitB}>
            <div className={styles.formGroup}>
              <input
                type="text"
                name="leasePrice"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Lease Price"
                value={formik.values.leasePrice}
              />
              {formik.errors.leasePrice && formik.touched.leasePrice && <div className={styles.error}>{formik.errors.leasePrice}</div>}
            </div>
            <div className={styles.formGroup}>
              <select
                name="duration"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onClick={() => handleArrowToggle('duration')}
                value={formik.values.duration}
              >
                <option value="" label="Duration" />
                <option value="daily" label="Daily" />
                <option value="weekly" label="Weekly" />
                <option value="monthly" label="monthly" />
                <option value="yearly" label="Yearly" />
              </select>
              {!arrowToggle.duration ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
              {formik.errors.duration && formik.touched.duration && <div className={styles.error}>{formik.errors.duration}</div>}
            </div>
          </div>
        </div>

        <button type="submit" className={styles.button}>Submit</button>
      </motion.form>
      {isLoading && <Loader loading={isLoading} />}
      {success && <Success title="We got your request" text="One of our top agents will be in touch shortly!" link="Continue Shopping" home="" />}
    </>
  );
};

export default Equipment;
