const FAQ = [
  {
    id: 1,
    question: 'What is PALMSHOPS?',
    answer: 'PALMSHOPS is an integrated agritech platform designed to empower farmers, enhance food traceability, and promote community prosperity through advanced technology.',
  },
  {
    id: 2,
    question: 'Is there a cost associated with using PALMSHOPS?',
    answer: 'Basic access is free, but we offer premium features and services with subscription plans.',
  },
  {
    id: 3,
    question: 'What does Palmtrack offer in terms of inventory management?',
    answer: 'Palmtrack allows you to monitor your farm inventory, track crop growth, input usage, and ensures complete traceability from farm to fork.',
  },
  {
    id: 4,
    question: 'Can I buy and sell produce on Palmstore?',
    answer: 'Yes, Palmstore is our digital marketplace where you can both buy and sell farm produce.',
  },
  {
    id: 5,
    question: 'How can I request a loan through Palmvest?',
    answer: 'Palmvest provides access to microloans for inputs and personal use. You can apply directly through the platform.',
  },
  {
    id: 6,
    question: 'How can I get in touch with customer support?',
    answer: "Contact our customer support team through the platform's support center.",
  },
  {
    id: 7,
    question: 'Are there specific requirements for using the platform?',
    answer: 'PALMSHOPS is designed to be user-friendly. All you need is an internet connection and a desire to explore agricultural innovation.',
  },
  {
    id: 8,
    question: 'How does PALMSHOPS contribute to sustainable agriculture?',
    answer: 'Our platform encourages sustainable farming practices, promotes food traceability, and supports economic growth within farming communities.',
  },
  {
    id: 9,
    question: 'How do I get started with PALMSHOPS?',
    answer: 'To get full access to all our features, subscribe to a premium plan. You can also explore our free features and services.',
  },
  {
    id: 10,
    question: 'How secure is the data I input into the platform?',
    answer: 'We prioritize data security and use advanced encryption techniques to safeguard your information.',
  },
  {
    id: 11,
    question: "What is the benefit of Palmvest's investment opportunities?",
    answer: 'Palmvest offers the chance to invest in farms and gain returns, supporting community financial growth.',
  },
  {
    id: 12,
    question: 'Is the platform available on mobile devices?',
    answer: 'Yes, PALMSHOPS is accessible on both mobile devices and desktop computers.',
  },
  {
    id: 13,
    question: 'Can I share my farming knowledge on the platform?',
    answer: 'Yes, we encourage knowledge sharing. Join our community of learners and share your expertise through Palmschool.',
  },
  {
    id: 14,
    question: 'Can I lease farmland through Palmstore?',
    answer: 'Yes, Palmstore offers opportunities to lease farmland. Explore available listings on the platform.',
  },
  {
    id: 15,
    question: 'How does the points and ranking system work, and what benefits does it offer to farmers on the platform?',
    answer: "The points and ranking system at PALMSHOPS is designed to reward and promote continuous learning among our farmers. As farmers engage with courses and modules on Palmschool, participate in community discussions, and showcase their knowledge, they earn points and improve their ranking. This not only provides recognition for their dedication but also offers practical advantages. Farmers with higher rankings are considered more favorably when selling their produce, giving them faster access to the marketplace. Additionally, a higher rank can boost their eligibility and attractiveness when applying for loans, creating a powerful incentive for ongoing education and skill development. It's a win-win, as farmers enhance their expertise and reap the benefits of their commitment to learning.",
  },
  {
    id: 16,
    question: 'What is Palmschool, and how can it benefit me?',
    answer: 'Palmschool is our e-learning section. It provides access to comprehensive agricultural education, empowering you with knowledge for sustainable farming practices.',
  },
];

export default FAQ;
