/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './About.module.scss';
import about from '../../assets/about.webp';
import cassava from '../../assets/cassava.webp';
import oilpalm from '../../assets/oilpalm.webp';
import potatoes from '../../assets/potatoes.webp';
import ladyFarmer from '../../assets/lady_farmer.webp';
import cheerFarmer from '../../assets/cheer_farmer.webp';
import subtract from '../../assets/desk_farm.webp';
import mamaHarvest from '../../assets/mama_harvest.webp';

// eslint-disable-next-line react/prop-types
const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 400,
      easing: 'ease-in',
      once: true,
    });
  }, []);

  return (
    <section id="about" className={styles.aboutContainer} style={{ overflow: 'hidden' }}>
      <article className={styles.aboutOne} data-aos="fade-out-in">
        <div className={styles.aboutContent}>
          <h2 className={styles.aboutHeader}>
            About Us
          </h2>
          <p className={styles.aboutParagraph}>
            At
            <span className={styles.bold}> PALMSHOPS</span>
            , we envision a world where agriculture is a source of prosperity,
            sustainability, and empowerment.
          </p>
        </div>
        <img
          src={about}
          alt="PalmShops.com about"
          className={styles.aboutImage}
        />
      </article>
      <article className={styles.aboutTwo}>
        <img
          src={oilpalm}
          alt="PalmShops.com oilpalm"
          className={styles.aboutImage}
          data-aos="fade-left"
        />
        <img
          src={cassava}
          alt="PalmShops.com cassava"
          className={styles.aboutImage}
          data-aos="fade-left"
        />
        <img
          src={potatoes}
          alt="PalmShops.com potatoes"
          className={styles.aboutImage}
          data-aos="fade-left"
        />
      </article>
      <article className={styles.aboutThree}>
        <img
          src={ladyFarmer}
          alt="PalmShops.com lady farmer"
          className={styles.aboutImage}
        />
        <div className={styles.aboutContent} data-aos="fade-out">
          <h2 className={styles.aboutHeader}>
            Hurry and sign up on
            <span className={styles.tintGreen}> Palmshops </span>
          </h2>
          <p className={styles.aboutParagraph}>
            Are you ready to transform your farming experience and grow your income?
            Be among the first to access the innovative Palmshops platform designed for smallholder
            farmers and individuals looking to invest in the agro-based industry.
            Let us train you on sustainable agripreneurship and starting a profitable agribusiness.
          </p>
        </div>
      </article>
      <article className={styles.aboutFour}>
        <img
          src={cheerFarmer}
          alt="PalmShops.com about"
          className={styles.aboutImage}
        />
        <div className={styles.aboutContent} data-aos="fade-in">
          <h3 className={styles.aboutTitle}>
            Some of What You&apos;ll Get:
          </h3>
          <p className={styles.aboutParagraph}>
            Our in-depth insights into the thriving palm oil business,
            support for young agripreneurs like yourself, helping you keep track
            of your inventories, sourcing and supplying on request,
            among other premium services that we offer are currenty accessible for
            <span className={styles.bold}> FREE</span>
          </p>
          <ul className={styles.aboutList}>
            <li className={styles.aboutListItem}>
              How To Cultivate Oil Palm
            </li>
            <li className={styles.aboutListItem}>
              Steps Involved In Palm Oil Processing
            </li>
            <li className={styles.aboutListItem}>
              Importance Of Using Improved Processing Technology
            </li>
            <li className={styles.aboutListItem}>
              Different Products Made with Palm Oil
            </li>
            <li className={styles.aboutListItem}>
              Strategies For Marketing Palm Oil
            </li>
            <li className={styles.aboutListItem}>
              How To Make Money Exporting Palm Oil
            </li>
            <li className={styles.aboutListItem}>
              How To Raise Investment To Start Up A Palm Oil Business
            </li>
            <li className={styles.aboutListItem}>
              What It Takes To Start-Up A Palm Oil Business
            </li>
            <li className={styles.aboutListItem}>
              Big Names In The Industry
            </li>
            <li className={styles.aboutListItem}>
              10+ Plus ways to Earn on Palmshops
            </li>
          </ul>
          <p className={styles.aboutParagraph}>
            Expert advice and tips for successful agribusiness - Worth over $5000
          </p>
          <p className={styles.aboutParagraph}>
            Exclusive access to early platform features - Invaluable
          </p>
          <p className={styles.aboutParagraph}>
            Priority off-taking of crops.
          </p>
        </div>
      </article>
      <article className={styles.aboutFive}>
        <div className={styles.aboutImgDiv}>
          <img
            src={subtract}
            alt="PalmShops.com about"
            className={styles.aboutImage}
          />
          <img
            src={mamaHarvest}
            alt="PalmShops.com about"
            className={styles.aboutImage}
          />
        </div>
        <div className={styles.aboutContent} data-aos="fade-up">
          <h3 className={styles.aboutTitle}>
            15+ Plus Ways you can Earn on Palmshops
          </h3>
          <ol className={styles.aboutList}>
            <li className={styles.aboutListItem}>
              Learn improved ways to grow crops and earn more profit
            </li>
            <li className={styles.aboutListItem}>
              Be an Ambassador- Register farmers and earn points that can be converted to
              products or cash
            </li>
            <li className={styles.aboutListItem}>
              Be a fabricator and supply machineries
            </li>
            <li className={styles.aboutListItem}>
              Be an affiliate marketer and earn by referral
            </li>
            <li className={styles.aboutListItem}>
              Be a vendor and retail products from palmshops
            </li>
            <li className={styles.aboutListItem}>
              Becomes a wholesaler/distributor
            </li>
            <li className={styles.aboutListItem}>
              Lease your Processing facility on contract basis
            </li>
            <li className={styles.aboutListItem}>
              Earn by leasing palm plantations
            </li>
            <li className={styles.aboutListItem}>
              Be a processor and sell through Palmshops
            </li>
            <li className={styles.aboutListItem}>
              Invest in eco-palm fund or oil palm arbitrage
            </li>
            <li className={styles.aboutListItem}>
              Sell your expertise as a consultant in the marketplace
            </li>
            <li className={styles.aboutListItem}>
              Produce palm related products and sell it on our marketplace eg bodycare products,
              kernel oil
            </li>
            <li className={styles.aboutListItem}>
              Earn through collaborative exportation
            </li>
            <li className={styles.aboutListItem}>
              Sell seedlings on our marketplace
            </li>
            <li className={styles.aboutListItem}>
              Sign up as a logistic partner
            </li>
            <li className={styles.aboutListItem}>
              Earn by marketing your agribusiness course on palmschool.
            </li>
            <li className={styles.aboutListItem}>
              Supply your produce with traceable data to processing companies
            </li>
          </ol>
        </div>
      </article>
    </section>
  );
};

export default About;
