/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import styles from './css/Subscribe.module.scss';
import tractor from '../images/tractor.png';
import { auth } from '../../util';
import Success from '../../PalmStore/components/Success';
import Loader from './Loader';

const options = {
  theme: 'light',
  position: 'bottom-right',
  autoClose: 2000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const Subscribe = () => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!email) {
      toast.error('Email is required', options);
      setLoading(false);
      return;
    }
    try {
      const response = await auth.post('/subscribe', { email });
      if (response.status === 201 || response.status === 200) {
        setLoading(false);
        setSuccess(true);
        setEmail('');
      }
    } catch (error) {
      setLoading(false);
      toast.error('Your subscription request was not successful', options);
    }
  };

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <motion.article
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={styles.footerContact}
    >
      <div className={styles.footerContactContent}>
        <h3 className={styles.footerContactTitle}>
          Subscribe to our Newsletter
        </h3>
        <form className={styles.footerContactForm} onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.footerContactInput}
            required
          />
          <button type="submit" className={styles.footerContactButton}>
            Subscribe
          </button>
        </form>
        <img
          src={tractor}
          alt="Subscribe to our newsletter"
          className={styles.footerContactImage}
        />
      </div>
      {success && (
        <Success
          title="Subscription Successful"
          text="Thank you for subscribing to our newsletter"
          link="Back to Home"
          home="/"
        />
      )}
    </motion.article>
  );
};

export default Subscribe;
