/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-duplicates */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-underscore-dangle */
import React, {
  useRef, useState, useEffect,
} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { VscSettings } from 'react-icons/vsc';
import { BeatLoader } from 'react-spinners';
import { CiSearch } from 'react-icons/ci';
import { MdRemoveShoppingCart } from 'react-icons/md';
import hero from '../assets/hero.png';
import styles from './css/PalmStore.module.scss';
import Success from '../components/Success';
import Deal from '../components/Deal';
import ProductPage from '../components/ProductPage';
import Loader from '../../PalmSchool/components/Loader';
import Alert from '../components/Underdev';
import { auth } from '../../util';
import { chooseProduct } from '../../redux/reducer/productAction';
import { addProduct } from '../../redux/features/cartSlice';
import { fetchStoreProducts } from '../../redux/reducer/authActions';

// dispatch(removeProduct());
const animateBook = {
  isHidden: { opacity: 0, x: '-100%' },
  isVisible: (transit) => ({
    opacity: 1,
    x: 0,
    transition: { delay: transit },
  }),
};

const validationSchema = Yup.object().shape({
  product: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
});

const Market = () => {
  const store = useSelector((state) => state.auth.products);
  const [showPopup, setShowPopup] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [products, setProducts] = useState(store || []);
  const [isLoading, setIsLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { success, type } = useSelector((state) => state.cart);
  const selectedProduct = useSelector((state) => state.product.selectedProduct);
  const popupRef = useRef(null);
  const [popupVisible, setIsPopupVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const handlePopup = (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        setShowPopup(false);
      }
    };

    document.addEventListener('mousedown', handlePopup);

    return () => {
      document.removeEventListener('mousedown', handlePopup);
    };
  }, []);

  useEffect(() => {
    const res = dispatch(fetchStoreProducts());
    console.log(res);
    if (res.payload !== undefined) {
      setProducts(res.payload);
    }
  }, [products.length]);

  const filteredProducts = products.filter((product) => product.title.toLowerCase().includes(searchQuery.toLowerCase()));

  const handlePopupClick = () => {
    if (showPopup) {
      setShowPopup(false);
    } else if (isOpenForm) {
      setIsOpenForm(false);
    }
  };

  const handleSelectProduct = (product) => {
    dispatch(chooseProduct(product, 'palmstore'));
  };

  const handleForm = () => {
    setIsOpenForm(!isOpenForm);
    if (successful) {
      setIsOpenForm(false);
    }
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const formik = useFormik({
    initialValues: {
      product: '',
      description: '',
      phone: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const data = await auth.post('request/new', { ...values });
      if (data.status === 200 || data.status === 201) {
        setIsLoading(false);
        setIsOpenForm(false);
        setSuccessful(true);
        formik.resetForm();
      }
    },
  });

  const handleCart = async (selectedProduct) => {
    const res = await dispatch(addProduct({
      ...selectedProduct, quantity: 1, price: selectedProduct.price,
    }));
    if (res.payload && success) {
      setIsPopupVisible(true);
    } else {
      setIsPopupVisible(false);
    }
  };

  return (
    <motion.section
      initial="isHidden"
      animate="isVisible"
      variants={animateBook}
      className={styles.container}
    >
      <div className={styles.hero}>
        <img src={hero} alt="hero" className={styles.img} />
        <p className={styles.heroText}>Buy Fresh from PALMSTORE</p>
      </div>
      <div className={styles.products}>
        {!products && (
        <>
          <MdRemoveShoppingCart className={styles.cart} />
          <p className={styles.cartText}>No product available for now. You can send a request!</p>
        </>
        )}
        <div className={styles.searchDiv}>
          {products && (
          <div className={styles.search}>
            <CiSearch className={styles.iconic} />
            <input
              type="text"
              placeholder="Search products"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={styles.input}
            />
            <button type="button" className={styles.searchBtn}>
              <VscSettings className={styles.icon} />
            </button>
          </div>
          )}
          <p className={styles.cta} onClick={handleForm}>Make a Request</p>
        </div>
        {
            searchQuery && filteredProducts.length === 0 && (
              <div
                className={styles.noResult}
              >
                <BeatLoader
                  visible
                  size="15"
                  margin="2"
                  color="#a9d99f"
                  loading
                  speedMultiplier={1}
                  ariaLabel="beat-loader-loading"
                />
                <p>
                  No result found for &quot;
                  {searchQuery}
                  &quot;
                </p>
              </div>
            )
          }
        <AnimatePresence>
          <div className={styles.productList}>
            {popupVisible && <Alert visible={popupVisible} text={type} onClose={() => setIsPopupVisible(false)} />}
            {filteredProducts ? (
              filteredProducts.map((product, index) => (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.1 }}
                  whileTap={{ scale: 0.95 }}
                  key={`${product._id}-${index}`}
                  className={styles.productCard}
                  onClick={() => handleSelectProduct(product)}
                >
                  <div className={styles.productImg}>
                    <img src={product.productImage} alt={product.title} className={styles.img} />
                  </div>
                  <div className={styles.productDetails}>
                    <h4 className={styles.productName}>{product.title}</h4>
                    <p className={styles.productMetric}>{product.metric}</p>
                  </div>
                  <div className={styles.productPrice}>
                    <p className={styles.price}>
                      ₦
                      {product.price.toLocaleString()}
                    </p>
                    <motion.button
                      initial={{ opacity: 0, y: -50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      whileTap={{ scale: 0.9 }}
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCart(product);
                      }}
                      className={styles.buyBtn}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="27" viewBox="0 0 29 27" fill="none">
                        <rect x="0.332031" y="0.305298" width="28.6688" height="25.9811" rx="5.84127" fill="url(#paint0_linear_1743_14528)" />
                        <rect x="0.332031" y="0.305298" width="28.6688" height="25.9811" rx="5.84127" fill="black" fillOpacity="0.2" />
                        <mask id="path-2-inside-1_1743_14528" fill="white">
                          <path d="M19.2216 14.0905H15.0749V18.2956H13.4202V14.0905H9.29297V12.5915H13.4202V8.36694H15.0749V12.5915H19.2216V14.0905Z" />
                        </mask>
                        <path d="M19.2216 14.0905H15.0749V18.2956H13.4202V14.0905H9.29297V12.5915H13.4202V8.36694H15.0749V12.5915H19.2216V14.0905Z" fill="#42423A" />
                        <path d="M19.2216 14.0905V16.7782H21.9093V14.0905H19.2216ZM15.0749 14.0905V11.4028H12.3872V14.0905H15.0749ZM15.0749 18.2956V20.9833H17.7626V18.2956H15.0749ZM13.4202 18.2956H10.7325V20.9833H13.4202V18.2956ZM13.4202 14.0905H16.1079V11.4028H13.4202V14.0905ZM9.29297 14.0905H6.60527V16.7782H9.29297V14.0905ZM9.29297 12.5915V9.90378H6.60527V12.5915H9.29297ZM13.4202 12.5915V15.2792H16.1079V12.5915H13.4202ZM13.4202 8.36694V5.67924H10.7325V8.36694H13.4202ZM15.0749 8.36694H17.7626V5.67924H15.0749V8.36694ZM15.0749 12.5915H12.3872V15.2792H15.0749V12.5915ZM19.2216 12.5915H21.9093V9.90378H19.2216V12.5915ZM19.2216 11.4028H15.0749V16.7782H19.2216V11.4028ZM12.3872 14.0905V18.2956H17.7626V14.0905H12.3872ZM15.0749 15.6079H13.4202V20.9833H15.0749V15.6079ZM16.1079 18.2956V14.0905H10.7325V18.2956H16.1079ZM13.4202 11.4028H9.29297V16.7782H13.4202V11.4028ZM11.9807 14.0905V12.5915H6.60527V14.0905H11.9807ZM9.29297 15.2792H13.4202V9.90378H9.29297V15.2792ZM16.1079 12.5915V8.36694H10.7325V12.5915H16.1079ZM13.4202 11.0546H15.0749V5.67924H13.4202V11.0546ZM12.3872 8.36694V12.5915H17.7626V8.36694H12.3872ZM15.0749 15.2792H19.2216V9.90378H15.0749V15.2792ZM16.5339 12.5915V14.0905H21.9093V12.5915H16.5339Z" fill="white" mask="url(#path-2-inside-1_1743_14528)" />
                        <defs>
                          <linearGradient id="paint0_linear_1743_14528" x1="4.30421" y1="-19.9924" x2="48.4708" y2="-5.83732" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#7EC670" />
                            <stop offset="1" stopColor="#DCC15F" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </motion.button>
                  </div>
                </motion.div>
              ))) : (
              products.map((product, index) => (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.1 }}
                  whileTap={{ scale: 0.95 }}
                  key={`${product._id}-${index}`}
                  className={styles.productCard}
                  onClick={() => handleSelectProduct(product)}
                >
                  <div className={styles.productImg}>
                    <img src={product.productImage} alt={product.title} className={styles.img} />
                  </div>
                  <div className={styles.productDetails}>
                    <h4 className={styles.productName}>{product.title}</h4>
                    <p className={styles.productMetric}>{product.metric}</p>
                  </div>
                  <div className={styles.productPrice}>
                    <p className={styles.price}>
                      ₦
                      {product.price.toLocaleString()}
                    </p>
                    <motion.button
                      initial={{ opacity: 0, y: -50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      whileTap={{ scale: 0.9 }}
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCart(product);
                      }}
                      className={styles.buyBtn}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="27" viewBox="0 0 29 27" fill="none">
                        <rect x="0.332031" y="0.305298" width="28.6688" height="25.9811" rx="5.84127" fill="url(#paint0_linear_1743_14528)" />
                        <rect x="0.332031" y="0.305298" width="28.6688" height="25.9811" rx="5.84127" fill="black" fillOpacity="0.2" />
                        <mask id="path-2-inside-1_1743_14528" fill="white">
                          <path d="M19.2216 14.0905H15.0749V18.2956H13.4202V14.0905H9.29297V12.5915H13.4202V8.36694H15.0749V12.5915H19.2216V14.0905Z" />
                        </mask>
                        <path d="M19.2216 14.0905H15.0749V18.2956H13.4202V14.0905H9.29297V12.5915H13.4202V8.36694H15.0749V12.5915H19.2216V14.0905Z" fill="#42423A" />
                        <path d="M19.2216 14.0905V16.7782H21.9093V14.0905H19.2216ZM15.0749 14.0905V11.4028H12.3872V14.0905H15.0749ZM15.0749 18.2956V20.9833H17.7626V18.2956H15.0749ZM13.4202 18.2956H10.7325V20.9833H13.4202V18.2956ZM13.4202 14.0905H16.1079V11.4028H13.4202V14.0905ZM9.29297 14.0905H6.60527V16.7782H9.29297V14.0905ZM9.29297 12.5915V9.90378H6.60527V12.5915H9.29297ZM13.4202 12.5915V15.2792H16.1079V12.5915H13.4202ZM13.4202 8.36694V5.67924H10.7325V8.36694H13.4202ZM15.0749 8.36694H17.7626V5.67924H15.0749V8.36694ZM15.0749 12.5915H12.3872V15.2792H15.0749V12.5915ZM19.2216 12.5915H21.9093V9.90378H19.2216V12.5915ZM19.2216 11.4028H15.0749V16.7782H19.2216V11.4028ZM12.3872 14.0905V18.2956H17.7626V14.0905H12.3872ZM15.0749 15.6079H13.4202V20.9833H15.0749V15.6079ZM16.1079 18.2956V14.0905H10.7325V18.2956H16.1079ZM13.4202 11.4028H9.29297V16.7782H13.4202V11.4028ZM11.9807 14.0905V12.5915H6.60527V14.0905H11.9807ZM9.29297 15.2792H13.4202V9.90378H9.29297V15.2792ZM16.1079 12.5915V8.36694H10.7325V12.5915H16.1079ZM13.4202 11.0546H15.0749V5.67924H13.4202V11.0546ZM12.3872 8.36694V12.5915H17.7626V8.36694H12.3872ZM15.0749 15.2792H19.2216V9.90378H15.0749V15.2792ZM16.5339 12.5915V14.0905H21.9093V12.5915H16.5339Z" fill="white" mask="url(#path-2-inside-1_1743_14528)" />
                        <defs>
                          <linearGradient id="paint0_linear_1743_14528" x1="4.30421" y1="-19.9924" x2="48.4708" y2="-5.83732" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#7EC670" />
                            <stop offset="1" stopColor="#DCC15F" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </motion.button>
                  </div>
                </motion.div>
              ))
            )}
          </div>
          <Deal />
        </AnimatePresence>
      </div>
      {showPopup && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        whileTap={{ opacity: 0, transition: { duration: 0.3, delay: 0.1 } }}
        className={styles.request}
        ref={popupRef}
        onClick={handlePopupClick}
      >
        <button type="button" className={styles.requestBtn} onClick={handleForm}>
          Can&apos;t find what you need?
          Tap to make a request!
        </button>
      </motion.div>
      )}
      {(isOpenForm) && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        whileTap={{ opacity: 0, transition: { duration: 0.3, delay: 0.1 } }}
        className={styles.request}
        ref={popupRef}
        onClick={handlePopupClick}
      >
        <form className={styles.requestForm} onSubmit={formik.handleSubmit} onClick={(event) => stopPropagation(event)}>
          <h2 className={styles.formTitle}>Request Form</h2>
          <div className={styles.formGroup}>
            <label htmlFor="product" className={styles.label}>Product name</label>
            <input
              type="text"
              name="product"
              onChange={formik.handleChange}
              value={formik.values.farmSize}
            />
            {formik.errors.product && formik.touched.product && <div className={styles.error}>{formik.errors.product}</div>}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="description" className={styles.label}>Brief description</label>
            <input
              type="text"
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
            />
            {formik.errors.description && formik.touched.description && <div className={styles.error}>{formik.errors.description}</div>}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="phone" className={styles.label}>Phone number</label>
            <input
              type="text"
              name="phone"
              onChange={formik.handleChange}
              value={formik.values.phone}
            />
            {formik.errors.phone && formik.touched.phone && <div className={styles.error}>{formik.errors.phone}</div>}
          </div>
          <button type="submit" className={styles.submitBtn}>Send</button>
        </form>
      </motion.div>
      )}
      {successful && <Success title="We got your request" text="One of our top agents will be in touch shortly!" home="" link="Continue Shopping" />}
      {isLoading && <Loader loading={isLoading} />}
      {selectedProduct && selectedProduct.source === 'palmstore' && (
        <ProductPage product={selectedProduct} deal={false} />
      )}
    </motion.section>
  );
};

export default Market;
