import React from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import Nav from '../Nav/Nav';
import styles from './Home.module.scss';
import HeroPage from '../PalmSchool/components/HeroPage';
import Palm from '../PalmSchool/components/Palm';
import Subscribe from '../PalmSchool/components/Subscribe';
import Footer from '../PalmSchool/components/Footer';

const Home = () => {
  const auth = useSelector((state) => state.auth.isAuthenticated);

  window.scrollTo(0, 0);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={styles.container}
    >
      <Nav auth={auth} />
      <HeroPage auth={auth} />
      <Palm />
      <Subscribe />
      <Footer />
    </motion.div>
  );
};

export default Home;
