/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { MdImage } from 'react-icons/md';
import { FaMapLocationDot } from 'react-icons/fa6';
import { motion } from 'framer-motion';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import Success from '../../components/Success';
import Loader from '../../../PalmSchool/components/Loader';
import states from '../../../PalmTrack/assets/states.json';
import styles from './Form.module.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { auth } from '../../../util';
import Alert from '../../components/Underdev';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const validationSchema = Yup.object().shape({
  sale: Yup.bool().test(
    'leaseOrSaleSelected',
    'At least one of Sale or Lease must be selected',
    (value, { sale, lease }) => value || sale || lease,
  ),
  lease: Yup.bool().test(
    'leaseOrSaleSelected',
    'At least one of Sale or Lease must be selected',
    (value, { sale, lease }) => value || sale || lease,
  ),
  salesPrice: Yup.number().when('sale', {
    is: true,
    then: () => Yup.number().required('Sales price is required')
      .positive('Sales price must be a positive number'),
    otherwise: () => Yup.number().notRequired(),
  }),
  leasePrice: Yup.number().when('lease', {
    is: true,
    then: () => Yup.number().required('Lease price is required').positive('Lease price must be a positive number'),
    otherwise: () => Yup.number().notRequired(),
  }),
  duration: Yup.string().when('lease', {
    is: true,
    then: () => Yup.string().required('Lease duration is required'),
    otherwise: () => Yup.string().notRequired(),
  }),

  cropPlanted: Yup.string().required('Type of crop planted is required'),
  farmSize: Yup.number().required('The farm size is required'),
  farmSizeUnit: Yup.string().required('The farm size unit of measurement is required'),
  description: Yup.string(),
  address: Yup.string().required('Address is required'),
  state: Yup.string().required('Choose a state'),
  lga: Yup.string().required('Select a Local Government Area'),
  productImage: Yup.mixed().required('Upload an image of the product'),
  geolocation: Yup.object()
    .shape({
      latitude: Yup.number().required('Latitude value is required'),
      longitude: Yup.number().required('Longitude value is required'),
    })
    .required('Geolocation is required')
    .test(
      'geolocation-not-null',
      'Please capture the geolocation again.',
      (value) => value !== null
      ,
    ),
});

const Farm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [badImg, setBadImg] = useState({ status: false, message: '' });
  const [arrowToggle, setArrowToggle] = useState({
    cropPlanted: false,
    state: false,
    lga: false,
    duration: false,
    farmSizeUnit: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: {
      lease: false,
      sale: false,
      cropPlanted: '',
      farmSize: '',
      farmSizeUnit: '',
      description: '',
      productImage: null,
      geolocation: null,
      address: '',
      state: '',
      lga: '',
      salesPrice: '',
      leasePrice: '',
      duration: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const data = await auth.post('/trackfarm/new', { ...values, farmSize: Math.abs(Number(values.farmSize)) });
        if (data.status === 201 || data.status === 200) {
          setIsLoading(false);
          setSuccess(true);
          formik.resetForm();
        }
      } finally {
        setIsLoading(false);
      }
      setIsLoading(false);
    },
  });

  const convertToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxSize: 1024000,
  });

  const options = {
    theme: 'light',
    position: 'bottom-right',
    autoClose: 2000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      acceptedFiles.map(async (file) => {
        const base64Image = await convertToBase64(file);
        formik.setFieldValue('productImage', base64Image);
      });
    }

    if (fileRejections.length > 0) {
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach((error) => {
          if (error.code === 'file-too-large') {
            setBadImg({ status: true, message: `"${file.name}" is too large. Maximum size is 1MB.` });
          } else if (error.code === 'file-invalid-type') {
            setBadImg({ status: true, message: `"${file.name}" is not a valid image type. Only JPEG and PNG images are accepted.` });
          }
        });
      });
    }
  }, [acceptedFiles, fileRejections]);

  const handleArrowToggle = (field) => {
    setArrowToggle((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const geocoderContainerRef = useRef(null);
  const geocoderRef = useRef(null);
  const mapContainerRef = useRef(null);
  const map = useRef(null);

  const captureGeolocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      formik.setFieldValue('geolocation', { longitude, latitude });
      if (map.current) {
        map.current.flyTo({ center: [latitude, longitude], zoom: 100 });
      }
    },
    (error) => {
      toast.error('Error getting geolocation', options);
      console.error(error);
    });
  };

  useEffect(() => {
    if (mapContainerRef.current && !map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [6, 5],
        zoom: 16,
        attributionControl: false,
      });
      map.current.addControl(new mapboxgl.FullscreenControl(), 'top-right');
      map.current.addControl(new mapboxgl.ScaleControl(), 'bottom-right');
      map.current.addControl(new mapboxgl.GeolocateControl(), 'top-right');
    }

    return () => {
      if (map.current) {
        try {
          map.current.remove();
        } catch (error) {
          console.error('Error removing the map:', error);
        }
      }
    };
  }, []);

  useEffect(() => {
    if (geocoderContainerRef.current && !geocoderRef.current) {
      geocoderRef.current = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        placeholder: 'Enter address',
        types: 'address, country, region, place, postcode, locality, neighborhood',
      });

      geocoderRef.current.addTo(geocoderContainerRef.current);

      geocoderRef.current.on('result', (event) => {
        const { result } = event;
        formik.setFieldValue('address', result.place_name);
      });
    }
  }, []);

  return (
    <>
      <motion.form
        initial={{ opacity: 0, x: '-100%' }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: '100%' }}
        transition={{ delay: 0.5 }}
        onSubmit={formik.handleSubmit}
        className={styles.container}
      >
        <div className={styles.groupA}>
          <p className={styles.label}>Select as appropriate</p>
          <div className={styles.formGroupB}>
            <label htmlFor="sale" className={styles.formCombo}>
              <input
                type="checkbox"
                name="sale"
                onChange={formik.handleChange}
                checked={formik.values.sale}
                onBlur={formik.handleBlur}
                value
                className={styles.inputB}
              />
              Sale
              <span className={styles.checkmarkB} />
            </label>
            <label htmlFor="lease" className={styles.formCombo}>
              <input
                type="checkbox"
                name="lease"
                checked={formik.values.lease}
                onChange={formik.handleChange}
                value
                className={styles.inputB}
              />
              Lease
              <span className={styles.checkmarkB} />
            </label>
          </div>
          {formik.errors.lease && formik.touched.lease && <div className={styles.error}>{formik.errors.lease}</div>}
          <div className={styles.formGroup}>
            <select
              name="cropPlanted"
              onChange={formik.handleChange}
              onClick={() => handleArrowToggle('cropPlanted')}
              onBlur={formik.handleBlur}
              value={formik.values.cropPlanted}
            >
              <option value="" label="Select type of crop planted" />
              <option value="maize" label="Maize" />
              <option value="oil_palm" label="Oil Palm" />
              <option value="cassava" label="Cassava" />
              <option value="cucumber" label="Cucumber" />
              <option value="none" label="None" />
            </select>
            {!arrowToggle.cropPlanted ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
            {formik.errors.cropPlanted && formik.touched.cropPlanted && <div className={styles.error}>{formik.errors.cropPlanted}</div>}
          </div>

          <div className={styles.formSplitB}>
            <div className={styles.formGroup}>
              <input
                type="number"
                name="farmSize"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Farm Size"
                value={formik.values.farmSize}
              />
              {formik.errors.farmSize && formik.touched.farmSize && <div className={styles.error}>{formik.errors.farmSize}</div>}
            </div>
            <div className={styles.formGroup}>
              <select
                name="farmSizeUnit"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onClick={() => handleArrowToggle('farmSizeUnit')}
                value={formik.values.farmSizeUnit}
              >
                <option value="" label="Unit" />
                <option value="sqm" label="SqM" />
                <option value="ha" label="Ha" />
                <option value="acres" label="Acres" />
              </select>
              {!arrowToggle.farmSizeUnit ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
              {formik.errors.farmSizeUnit && formik.touched.farmSizeUnit && <div className={styles.error}>{formik.errors.farmSizeUnit}</div>}
            </div>
          </div>

          <div className={styles.formGroup}>
            <input
              type="text"
              name="description"
              onChange={formik.handleChange}
              placeholder="Brief description"
              value={formik.values.description}
            />
            {formik.errors.description && formik.touched.description && <div className={styles.error}>{formik.errors.description}</div>}
          </div>

          <div className={styles.formGroup}>
            <div {...getRootProps()} className={styles.dropzone}>
              <input {...getInputProps()} />
              {formik.values.productImage ? (
                <div className={styles.image}>
                  <img src={formik.values.productImage} alt="product" />
                  <p>Uploaded Image</p>
                </div>
              ) : (
                <div className={styles.image}>
                  <MdImage className={styles.img} />
                  <p>Upload image (1Mb max)</p>
                </div>
              )}
            </div>
            {formik.errors.productImage && formik.touched.productImage && <div className={styles.error}>{formik.errors.productImage}</div>}
            {badImg.status && <Alert visible={badImg.status} text={badImg.message} onClose={() => setBadImg(!badImg.status)} />}
          </div>

          <div className={styles.control} style={{ overflow: 'hidden' }}>
            <div className={styles.mapContainer} ref={mapContainerRef} />
            <motion.button
              type="button"
              initial={{ opacity: 0, width: 0 }}
              animate={{ opacity: 1, width: '100px' }}
              transition={{ delay: 0.5 }}
              whileTap={{ opacity: 0.8 }}
              className={styles.CaptureButtonB}
              onClick={captureGeolocation}
            >
              {!formik.values.geolocation ? 'Get Geolocation' : <FaMapLocationDot className={styles.iconMap} />}
            </motion.button>
            {formik.values.geolocation && (
              <motion.p
                initial={{ opacity: 0, x: '-50%' }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.5, duration: 0.3 }}
                className={styles.mapDisc}
              >
                Lat:
                {' '}
                {formik.values.geolocation.latitude}
                ,
                {' '}
                Long:
                {' '}
                {formik.values.geolocation.longitude}
              </motion.p>
            )}
          </div>

          <div className={`${styles.formGroup} ${styles.farmDiv}`}>
            <label htmlFor="address">Location</label>
            <div ref={geocoderContainerRef} className={styles.geocoderContainer} />
            {formik.errors.address && formik.touched.address && <div className={styles.error}>{formik.errors.address}</div>}
          </div>
          <div className={styles.formSplit}>
            <div className={styles.formGroup}>
              <select
                name="state"
                onChange={formik.handleChange}
                onClick={() => handleArrowToggle('state')}
                onBlur={formik.handleBlur}
                value={formik.values.state}
              >
                <option value="" label="State" />
                {states.map((state) => <option key={state.state} value={state.state} label={state.state} />)}
              </select>
              {!arrowToggle.state ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
              {formik.errors.state && formik.touched.state && <div className={styles.error}>{formik.errors.state}</div>}
            </div>

            <div className={styles.formGroup}>
              <select
                name="lga"
                onChange={formik.handleChange}
                onClick={() => handleArrowToggle('lga')}
                onBlur={formik.handleBlur}
                value={formik.values.lga}
              >
                <option value="" label="LGA" />
                {states.map((state) => (state.state === formik.values.state) && state.lgas.map((lga) => <option key={lga} value={lga} label={lga} />))}
              </select>
              {!arrowToggle.lga ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
              {formik.errors.lga && formik.touched.lga && <div className={styles.error}>{formik.errors.lga}</div>}
            </div>
          </div>
          <div className={styles.formGroup}>
            <input
              type="text"
              name="salesPrice"
              onChange={formik.handleChange}
              placeholder="Sales Price"
              value={formik.values.salesPrice}
            />
            {formik.errors.salesPrice && formik.touched.salesPrice && <div className={styles.error}>{formik.errors.salesPrice}</div>}
          </div>
          <div className={styles.formSplitB}>
            <div className={styles.formGroup}>
              <input
                type="text"
                name="leasePrice"
                onChange={formik.handleChange}
                placeholder="Lease Price"
                value={formik.values.leasePrice}
              />
              {formik.errors.leasePrice && formik.touched.leasePrice && <div className={styles.error}>{formik.errors.leasePrice}</div>}
            </div>
            <div className={styles.formGroup}>
              <select
                name="duration"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onClick={() => handleArrowToggle('duration')}
                value={formik.values.duration}
              >
                <option value="" label="Duration" />
                <option value="daily" label="Daily" />
                <option value="weekly" label="Weekly" />
                <option value="monthly" label="monthly" />
                <option value="yearly" label="Yearly" />
              </select>
              {!arrowToggle.duration ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
              {formik.errors.duration && formik.touched.duration && <div className={styles.error}>{formik.errors.duration}</div>}
            </div>
          </div>
        </div>

        <button type="submit" className={styles.button}>Submit</button>
      </motion.form>
      {isLoading && <Loader />}
      {success && <Success title="We got your request" text="One of our top agents will be in touch shortly!" link="Continue Shopping" home="" />}
    </>
  );
};

export default Farm;
