import React from 'react';
import './App.css';
import Layout from './Routes/Layout';
import VideoPlaybackProvider from './PalmSchool/components/context/VideoContext';

function App() {
  return (
    <>
      <VideoPlaybackProvider>
        <Layout />
      </VideoPlaybackProvider>
    </>
  );
}

export default App;
