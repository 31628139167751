/* eslint-disable import/extensions */
/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import {
  Routes, Route, useLocation,
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Home from '../Home/Home';
import SignUp from '../PalmSchool/pages/SignUp';
import Confirm from '../PalmSchool/pages/Confirm';
import Login from '../PalmSchool/pages/Login';
import FindAccount from '../component/FindAccount';
import ResetPassword from '../component/ResetPassword';
import VerifyReset from '../component/VerifyReset';
import DesktopAccess from '../Home/LandingPage/DesktopAccess';

const UnAuth = () => {
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('lastVisited', location.pathname);
  }, [location]);

  return (
    <AnimatePresence>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/confirm-phone" element={<Confirm />} />
        <Route path="/verify-otp" element={<VerifyReset />} />
        <Route path="/find-my-account" element={<FindAccount />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Login />} />
        <Route path="/introducing-palmshops_mobile-web-app" element={<DesktopAccess />} />
      </Routes>
    </AnimatePresence>
  );
};

export default UnAuth;
