/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FaCirclePlus } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import NavB from '../../../Nav/NavB';
import styles from './Stock.module.scss';
import RenderForm from '../../components/RenderForm';
import { getHarvestStock, deleteHarvestStock } from '../../../redux/reducer/harvestAction';
import { getFarmStock, deleteFarmStock } from '../../../redux/reducer/farmAction';
import { openForm } from '../../../redux/features/navigationSlice';
import Loader from '../../../PalmSchool/components/Loader';

const animateBook = {
  isHidden: { opacity: 0, x: '-100%' },
  isVisible: (transit) => ({
    opacity: 1,
    x: 0,
    transition: { delay: transit },
  }),
};

const Stock = () => {
  const dispatch = useDispatch();
  const isOpenForm = useSelector((state) => state.navigation.isOpenForm);
  const { harvested } = useSelector((state) => state.harvest);
  const { farms } = useSelector((state) => state.farm);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const store = [...harvested, ...farms];

  useEffect(() => {
    dispatch(getHarvestStock());
    dispatch(getFarmStock());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return <Loader loading={loading} />;
  }

  const findProductLocation = (productId) => {
    const harvestedProduct = harvested.some((product) => product._id === productId);
    const farmProduct = farms.some((product) => product._id === productId);
    if (harvestedProduct) {
      return 'harvested';
    } if (farmProduct) {
      return 'farms';
    }
    return null;
  };

  const editStockHandler = (crop) => {
    const productLocation = findProductLocation(crop._id);
    if (productLocation === 'harvested') {
      navigate(`/palmTrack/harvest/${crop._id}/edit`);
    } else if (productLocation === 'farms') {
      navigate(`/palmTrack/farm/${crop._id}/edit`);
    }
  };

  const deleteStockHandler = (id) => {
    const productLocation = findProductLocation(id);

    if (productLocation === 'harvested') {
      dispatch(deleteHarvestStock(id));
    } else if (productLocation === 'farms') {
      dispatch(deleteFarmStock(id));
    } else {
      toast.error('Product not found or network error');
    }
  };

  return (
    <>
      <NavB palmtrack palmschool={false} palmvest={false} palmstore={false} />
      <section className={styles.stockContainer}>
        <h3 className={styles.title}>My Stocks</h3>
        <AnimatePresence>
          {store.length > 0 ? store.map((stock, index) => (
            <motion.article
              key={stock._id}
              variants={animateBook}
              initial="isHidden"
              animate="isVisible"
              exit="isHidden"
              layoutId={index}
              transit={(index + 1) * 0.2}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 1.05 }}
              onClick={() => editStockHandler(stock)}
              className={styles.storeCard}
            >
              <div className={styles.splitter}>
                <img src={stock.productImage} alt="" className={styles.img} />
                <div className={styles.productInfo}>
                  <h4 className={styles.name}>{stock.cropPlanted || stock.productName}</h4>
                  <p className={styles.text}>
                    <span>N</span>
                    {stock.pricePerUnit.toLocaleString()}
                  </p>
                  <p className={styles.text}>
                    <span>Quantity: </span>
                    {stock.expectedYield || stock.availableQty}
                  </p>
                  <p className={styles.text}>
                    <span>Location: </span>
                    {stock.address}
                  </p>
                </div>
              </div>
              <div className={styles.actionDiv}>
                <RiDeleteBinLine
                  className={styles.del}
                  onClick={(e) => { e.stopPropagation(); deleteStockHandler(stock._id); }}
                />
                <button
                  type="button"
                  className={styles.edit}
                  onClick={(e) => { e.stopPropagation(); editStockHandler(stock); }}
                >
                  Edit
                </button>
              </div>
            </motion.article>
          )) : <p className={styles.empty}>No stock available</p>}
        </AnimatePresence>
        <svg width="0" height="0">
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#7ec670', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#dcc15f', stopOpacity: 1 }} />
          </linearGradient>
        </svg>
        <FaCirclePlus onClick={() => dispatch(openForm())} className={styles.icon} style={{ fill: 'url(#grad1)' }} />
        {isOpenForm && (
        <article className={styles.formDiv}>
          <RenderForm stock />
        </article>
        )}
      </section>
    </>
  );
};

export default Stock;
