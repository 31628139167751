/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-duplicates */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-underscore-dangle */
import React, {
  useState, useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdOutlineSell } from 'react-icons/md';
import { RiHome2Line } from 'react-icons/ri';
import { CiShop } from 'react-icons/ci';
import { motion } from 'framer-motion';
import { PiShoppingCartSimple } from 'react-icons/pi';
import { BsBuildingCheck } from 'react-icons/bs';
import NavB from '../../Nav/NavB';
import Sell from './sell/Sell';
import footer from '../components/css/Footer.module.scss';
import Cart from './Cart';
import OrderList from './OrderList';
import { navigateTo, goBack, selectCurrentPage } from '../../redux/features/navigationSlice';
import Market from './Market';
import { removeProduct } from '../../redux/reducer/productAction';

const navs = [
  { name: 'Home', link: '/', icon: <RiHome2Line className={footer.icon} /> },
  { name: 'Sell', link: '#', icon: <MdOutlineSell className={footer.icon} /> },
  { name: 'Market', link: '#', icon: <CiShop className={footer.icon} /> },
  { name: 'Cart', link: '#', icon: <PiShoppingCartSimple className={footer.icon} /> },
  { name: 'Orders', link: '#', icon: <BsBuildingCheck className={footer.icon} /> },
];

const PalmStore = () => {
  const [delay, setDelay] = useState(0.1);
  const currentPage = useSelector(selectCurrentPage);

  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNavigation = (page) => {
    if (page !== currentPage) {
      dispatch(navigateTo(page));
    }
  };

  const renderPage = () => {
    switch (currentPage) {
      case 'Market':
        return <Market />;
      case 'Cart':
        return <Cart />;
      case 'Orders':
        return <OrderList />;
      case 'Sell':
        return <Sell />;
      default:
        return <Market />;
    }
  };

  return (
    <>
      <NavB palmstore palmschool={false} palmtrack={false} palmvest={false} backAction={() => dispatch(goBack())} />

      {renderPage()}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.5, type: 'spring', stiffness: 100, delay: 0.5,
        }}
        className={footer.wrapper}
      >
        {navs.map((nav) => (
          <Link
            to={nav.link}
            key={nav.name}
            id={nav.name}
            onClick={() => {
              handleNavigation(nav.name);
              dispatch(removeProduct());
            }}
            className={`${footer.nav} ${currentPage === nav.name ? footer.active : ''}`}
            initial={{ x: -70 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5, delay: () => setDelay(delay + 0.2) }}
          >
            {nav.icon}
            <p className={footer.name}>{nav.name}</p>
          </Link>
        ))}
      </motion.div>
    </>
  );
};

export default PalmStore;
