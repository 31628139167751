/* eslint-disable import/extensions */
/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import {
  Routes, Route, useLocation,
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Home from '../Home/Home';
import WelcomePage from '../PalmSchool/pages/Courses/WelcomePage';
import Course from '../PalmSchool/pages/Courses/Course';
import NextModule from '../PalmSchool/pages/Courses/NextModule';
import Assessment from '../PalmSchool/pages/Assessment/Assessment';
import PalmTrack from '../PalmTrack/pages/Home/PalmTrack';
import Stock from '../PalmTrack/pages/Stock/Stock';
import PalmStore from '../PalmStore/pages/PalmStore';
import Feedback from '../PalmSchool/pages/Assessment/FeedBack';
import Search from '../PalmSchool/pages/Courses/Search';
import EditFarmStockDetails from '../PalmTrack/pages/Stock/EditStockDetails';
import EditHarvestStockDetails from '../PalmTrack/pages/Stock/EditHarvestStockDetails';
import DesktopAccess from '../Home/LandingPage/DesktopAccess';

const Auth = () => {
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('lastVisited', location.pathname);
  }, [location]);

  return (
    <AnimatePresence>
      <Routes location={location}>
        <Route path="/" element={<Home />} />
        <Route path="/palmSchool" element={<WelcomePage />} />
        <Route path="/palmschool/courses/:courseId" element={<Course />} />
        <Route path="/palmschool/courses/:courseId/:moduleId" element={<NextModule />} />
        <Route path="/palmschool/courses/:courseId/modules/:moduleId/assessment/" element={<Assessment />} />
        <Route path="/palmTrack" element={<PalmTrack />} />
        <Route path="/palmschool/courses/feedback/:courseId" element={<Feedback />} />
        <Route path="/palmTrack/mystock" element={<Stock />} />
        <Route path="/palmTrack/farm/:id/edit" element={<EditFarmStockDetails />} />
        <Route path="/palmTrack/harvest/:id/edit" element={<EditHarvestStockDetails />} />
        <Route path="/palmStore" element={<PalmStore />} />
        <Route path="/courses/search" element={<Search />} />
        <Route path="/introducing-palmshops_mobile-web-app" element={<DesktopAccess />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </AnimatePresence>
  );
};

export default Auth;
