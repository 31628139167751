/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { LuSendHorizonal } from 'react-icons/lu';
import styles from './Feedback.module.scss';
import { auth } from '../../../util';
import Loader from '../../components/Loader';
import Success from '../../../PalmStore/components/Success';

const options = {
  theme: 'light',
  position: 'bottom-right',
  autoClose: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const Feedback = () => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { courseId } = useParams();

  const handleRating = (rate) => {
    setRating(rate);
  };

  setTimeout(() => {
    setIsLoading(false);
  }, 3000);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (rating === 0) {
      toast.error('Please select a rating', options);
      return;
    }
    setIsLoading(true);
    try {
      const res = await auth.post(`courses/${courseId}/feedback`, { rating, comment });
      if (res.status === 200) {
        setIsLoading(false);
        setSuccess(true);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error('Failed to submit feedback', options);
    }
  };

  if (isLoading) {
    return <Loader loading={isLoading} />;
  }

  return (
    <motion.form
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      onSubmit={handleSubmit}
      className={styles.feedback}
    >
      <p className={styles.title}>You have reached the end of your assessment</p>
      <h2 className={styles.subtitle}>Please rate the course</h2>
      <div className={styles.rating}>
        {[1, 2, 3, 4, 5].map((star) => (
          <motion.span
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.3, duration: 0.1 }}
            key={star}
            className={star <= rating ? styles.filledStar : styles.emptyStar}
            onClick={() => handleRating(star)}
          >
            ★
          </motion.span>
        ))}
      </div>
      <div className={styles.comment}>
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Kindly send us a feedback"
          rows="3"
          className={styles.textarea}
        />
        <button type="submit" className={styles.submitBtn}>
          <LuSendHorizonal className={styles.icon} />
        </button>
      </div>
      <button
        type="button"
        className={styles.btn}
        onClick={() => window.location.replace('/palmSchool')}
      >
        Back to Courses
      </button>
      {success && (
        <Success
          title="Feedback submitted successfully"
          text="Thank you for your feedback"
          link="Back to Courses"
          home="/palmSchool"
        />
      )}
    </motion.form>
  );
};

export default Feedback;
