/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaArrowRight } from 'react-icons/fa6';
import { MdKeyboardBackspace } from 'react-icons/md';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'aos/dist/aos.css';
import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import styles from './css/Confirm.module.scss';
import { verifyOTP, resendOTP } from '../../redux/reducer/authActions';
import Loader from '../components/Loader';
import { publicApi, auth } from '../../util';

const options = {
  theme: 'light',
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const Confirm = ({ phoneNo, reset }) => {
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(false);

  const handleBackClick = () => {
    const lastVisited = localStorage.getItem('lastVisited') || '/';
    if (lastVisited && lastVisited !== window.location.pathname) {
      navigate(lastVisited);
    } else {
      navigate(-1);
    }
  };
  const formatPhoneNumber = (phone) => {
    const firstPart = phone.slice(0, 5);
    const lastPart = phone.slice(-2);
    const maskedPart = '*'.repeat(phone.length - 7);

    return `${firstPart}${maskedPart}${lastPart}`;
  };

  const {
    verified, phone,
  } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [otp, setOtp] = useState(['', '', '', '', '', '']);

  const handleOTPVerification = async () => {
    const enteredOTP = otp.join('');
    if (phoneNo) {
      try {
        if (otp.length < 6) {
          toast.info('OTP cannot be empty. Enter your 6 digits please', options);
          return;
        }
        setFetching(true);
        const response = await publicApi.post('/check-otp',
          { phoneNo, otp: enteredOTP });
        setFetching(false);
        toast.success(response.data.messsage, options);
        navigate('/reset-password');
      } catch (err) {
        setFetching(false);
        toast.error('Invalid OTP. Try again', options);
      }
    } else {
      dispatch(verifyOTP(enteredOTP));
    }
  };

  const handleResendOTP = async () => {
    if (phoneNo) {
      const response = await auth.post('/resend', { phoneNo });
      toast.success(response.data.messsage, options);
      if (reset) {
        navigate('/reset-password');
      }
    } else {
      dispatch(resendOTP());
      navigate('/confirm-phone');
    }
  };

  useEffect(() => {
    if (verified) {
      navigate('/login');
    }
  }, [verified, navigate]);

  return (
    <motion.section
      className={styles.container}
      initial={{ opacity: 0, x: '-100%' }}
      whileInView={{ opacity: 1, x: 0 }}
    >
      <MdKeyboardBackspace className={styles.angle} onClick={handleBackClick} />
      <article className={styles.title}>
        <h1 className={styles.subtitle}>Phone Number Verification</h1>
        <p className={styles.desc}>
          {`An OTP has been sent to ${(phone || phoneNo) && formatPhoneNumber(phone || phoneNo)}. Kindly enter the code
          in the boxes below`}
        </p>
        <OtpInput
          value={otp.join('')}
          onChange={(value) => setOtp(value.split(''))}
          numInputs={6}
          renderSeparator={<span />}
          placeholder="******"
          containerStyle={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
          }}
          inputStyle={{
            width: '3rem',
            height: '3rem',
            margin: '0 0.1rem',
            fontSize: '1.5rem',
            borderRadius: '4px',
            border: '1px solid #ced4da',
          }}
          isInputNum
          renderInput={(props, index) => <input {...props} key={index} />}
        />
        <button type="submit" onClick={handleOTPVerification} className={styles.btn} disabled={fetching}>
          Verify
          <FaArrowRight />
        </button>
        <p className={styles.link}>
          Didn&apos;t get the code?
          <Link to="#" onClick={handleResendOTP}>Resend OTP</Link>
        </p>
      </article>
      {fetching && <Loader loading={fetching} />}
    </motion.section>
  );
};

Confirm.defaultProps = {
  phoneNo: '',
  reset: false,
};

Confirm.propTypes = {
  phoneNo: PropTypes.string,
  reset: PropTypes.bool,
};
export default Confirm;
