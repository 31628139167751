/* eslint-disable arrow-body-style */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MdNavigateNext } from 'react-icons/md';
import { toast } from 'react-toastify';
import NavB from '../../../Nav/NavB';
import styles from './Assessment.module.scss';
import correct from '../../images/correct.svg';
import wrong from '../../images/wrong.svg';
import { auth } from '../../../util';
import Loader from '../../components/Loader';

const options = {
  theme: 'light',
  position: 'bottom-right',
  autoClose: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const Assessment = () => {
  const { courseId, moduleId } = useParams();
  const [assessments, setAssessments] = useState(null);
  const [subtitle, setSubtitle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userResponses, setUserResponses] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState(false);
  const [wrongAnswer, setWrongAnswer] = useState(false);
  const [modules, setModules] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchModule = async () => {
      try {
        const response = await auth.get(`/courses/${courseId}/${moduleId}`);
        setSubtitle(response.data.module.subtopic);
        setAssessments(response.data.module.assessments[0]);
        setModules(response.data.course.modules);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error('Failed to load assessment data', options);
      }
    };
    fetchModule();
  }, [courseId, moduleId]);

  const handleResponseChange = (option) => {
    const isMultiAnswer = Array.isArray(assessments.answers[currentQuestionIndex]) && assessments.answers[currentQuestionIndex].length > 1;
    setUserResponses((prevResponses) => {
      if (isMultiAnswer) {
        return (prevResponses.includes(option)
          ? prevResponses.filter((response) => response !== option)
          : [...prevResponses, option]);
      }
      return [option];
    });
  };

  const handleSubmission = () => {
    if (userResponses.length === 0) {
      toast.error('Please select an answer before proceeding', options);
      return;
    }
    const correctAnswers = assessments.answers[currentQuestionIndex];
    const isCorrect = Array.isArray(correctAnswers)
      ? userResponses.sort().toString() === correctAnswers.sort().toString()
      : userResponses[0] === correctAnswers;

    if (isCorrect) {
      setCorrectAnswer(true);
      setWrongAnswer(false);
    } else {
      setWrongAnswer(true);
      setCorrectAnswer(false);
    }
  };

  const handleNext = () => {
    if (currentQuestionIndex < assessments.questions.length - 1) {
      setUserResponses([]);
      setCorrectAnswer(false);
      setWrongAnswer(false);
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      const currentModuleIndex = modules.findIndex((module) => module._id === moduleId);
      if (currentModuleIndex < modules.length - 1) {
        const nextModuleId = modules[currentModuleIndex + 1]._id;
        navigate(`/palmschool/courses/${courseId}/${nextModuleId}`);
      } else {
        navigate(`/palmschool/courses/feedback/${courseId}`);
      }
    }
  };

  if (isLoading) return <Loader loading={isLoading} />;

  const currentQuestion = assessments.questions[currentQuestionIndex];
  const currentOptions = assessments.options[currentQuestionIndex];
  const isMultiAnswer = Array.isArray(assessments.answers[currentQuestionIndex]) && assessments.answers[currentQuestionIndex].length > 1;

  return (
    <>
      <NavB palmschool />
      <div className={styles.container}>
        <h1 className={styles.title}>{subtitle}</h1>
        <section className={styles.flexContent}>
          <article className={styles.subheading}>
            <h3 className={styles.subtitle}>Assessment</h3>
            <p className={styles.tag}>{`${currentQuestionIndex + 1} of ${assessments?.questions.length}`}</p>
          </article>
          <form className={styles.form}>
            <div className={styles.groupDiv}>
              <article className={styles.question}>
                <p className={styles.text}>{`Q${currentQuestionIndex + 1}:`}</p>
                <p className={styles.text}>{currentQuestion}</p>
              </article>
              <article className={styles.answer}>
                {currentOptions?.map((option, index) => (
                  isMultiAnswer ? (
                    <div key={index} className={styles.optionB}>
                      <input
                        type="checkbox"
                        name="answer"
                        value={option}
                        checked={userResponses.includes(option)}
                        onChange={(e) => handleResponseChange(e.target.value)}
                        className={styles.inputB}
                      />
                      <span className={styles.checkmarkB} />
                      <label htmlFor={`option-${index}`}>{option}</label>
                    </div>
                  ) : (
                    <div key={index} className={styles.option}>
                      <label htmlFor={`option-${index}`}>
                        {option}
                        <input
                          type="radio"
                          name="answer"
                          value={option}
                          checked={userResponses.includes(option)}
                          onChange={() => handleResponseChange(option)}
                          className={styles.input}
                        />
                        <span className={styles.checkmark} />
                      </label>
                    </div>
                  )
                ))}
              </article>
            </div>
            <button
              type="button"
              className={styles.btn}
              onClick={handleSubmission}
            >
              Submit
            </button>
          </form>
          {correctAnswer && (
            <article className={styles.correct}>
              <div className={styles.wrapper}>
                <img src={correct} alt="Correct answer" className={styles.icon} />
                <p className={styles.text}>
                  Correct!
                  <br />
                  You got it right
                </p>
                <button type="button" className={styles.link} onClick={handleNext}>
                  Next
                  <MdNavigateNext className={styles.icon} />
                </button>
              </div>
            </article>
          )}
          {wrongAnswer && (
            <article className={styles.correct}>
              <div className={styles.wrapperB}>
                <img src={wrong} alt="Wrong answer" className={styles.icon} />
                <p className={styles.text}>Sorry, You got it wrong</p>
                <p className={styles.text}>CORRECT ANSWER</p>
                <div className={styles.correctAnswer}>
                  {assessments?.answers[currentQuestionIndex].map((option, index) => (
                    <div key={index} className={styles.option}>
                      <input
                        type="radio"
                        name="correct-answer"
                        checked={option === assessments.answers[currentQuestionIndex]}
                        value={option}
                        className={styles.input}
                        readOnly
                      />
                      <label htmlFor={`correct-answer-${index}`}>{option}</label>
                    </div>
                  ))}
                </div>
                <button type="button" className={styles.link} onClick={handleNext}>
                  Next
                  <MdNavigateNext className={styles.icon} />
                </button>
              </div>
            </article>
          )}
        </section>
      </div>
    </>
  );
};

export default Assessment;
