/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import styles from './css/Summary.module.scss';
import Loader from '../../PalmSchool/components/Loader';

const animateBook = {
  isHidden: { opacity: 0, x: '-100%' },
  isVisible: (transit) => ({
    opacity: 1,
    x: 0,
    transition: { delay: transit },
  }),
};

const animatePro = {
  isHidden: { opacity: 0 },
  isVisible: {
    opacity: 1,
  },
};

const getDate = (order) => {
  const date = new Date(order);
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];
  return `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()}`;
};

const Summary = ({ order }) => {
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    if (order) {
      setisLoading(false);
    }
  }, [order]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.section
      className={styles.stockContainer}
      initial={{ opacity: 0.7 }}
      animate={{ opacity: 1 }}
      exit={{ transition: { duration: 0.1 } }}
    >
      <AnimatePresence initial={false}>
        <motion.section
          variants={animatePro}
          initial="isHidden"
          animate="isVisible"
          exit="isHidden"
          className={styles.section}
        >
          <h3 className={styles.title}>
            <span>Order: </span>
            {order.orderNumber}
          </h3>
          <h5 className={styles.sub}>{` ${getDate(order.createdAt)}`}</h5>
          <article className={styles.cartsDiv}>
            <AnimatePresence>
              {order.items.map((item, index) => (
                <motion.article
                  key={item._id}
                  variants={animateBook}
                  initial="isHidden"
                  animate="isVisible"
                  exit="isHidden"
                  layoutId={index}
                  transit={(index + 1) * 0.2}
                  className={styles.storeCard}
                >
                  <div className={styles.imgDiv}>
                    <img src={item.productImage} alt={item.title} className={styles.img} />
                  </div>
                  <div className={styles.productInfo}>
                    <div className={styles.textDiv}>
                      <h4 className={styles.name}>{item.title}</h4>
                      <p className={styles.text}>
                        ₦
                        {item.price.toLocaleString()}
                      </p>
                    </div>
                    <h4 className={styles.price}>
                      ₦
                      {(item.price * item.quantity).toLocaleString()}
                    </h4>
                  </div>
                </motion.article>
              ))}
            </AnimatePresence>
          </article>
          <article className={styles.orderInfo}>
            <p className={styles.text}>
              <span className={styles.tilt}>Delivery Option:</span>
              <span>{order.deliver === 'pickup' ? 'Shop Pick-up' : 'Home Delivery'}</span>
            </p>
            <p className={styles.text}>
              <span className={styles.tilt}>Payment Status:</span>
              <span>{order.status}</span>
            </p>
            <p className={styles.text}>
              <span className={styles.tilt}>Delivery Status:</span>
              <span>{order.deliveryStatus}</span>
            </p>
            <h4 className={styles.price}>
              <span className={styles.tilt} style={{ fontWeight: '700' }}>Total:</span>
              <span>
                ₦
                {order.totalAmount.toLocaleString()}
              </span>
            </h4>
          </article>
        </motion.section>
      </AnimatePresence>
      {isLoading && <Loader loading={isLoading} />}
    </motion.section>
  );
};

Summary.propTypes = {
  order: PropTypes.object.isRequired,
};

export default Summary;
