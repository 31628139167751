/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useDropzone } from 'react-dropzone';
import { useGeolocated } from 'react-geolocated';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { FaMapLocationDot } from 'react-icons/fa6';
import { MdImage } from 'react-icons/md';
import * as Yup from 'yup';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { addFarmStock } from '../../redux/reducer/farmAction';
import states from '../assets/states.json';
import styles from './css/RenderForm.module.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import Loader from '../../PalmSchool/components/Loader';
import Success from './Success';
import Alert from '../../PalmStore/components/Underdev';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const validationSchema = Yup.object().shape({
  cropPlanted: Yup.string().required('Enter Crop Planted'),
  typeOfSeedling: Yup.string().required('Type of Seedling is required'),
  fertilizerApplied: Yup.string().required('Fertilizer Applied is required'),
  farmSize: Yup.number().required('Farm Size is required'),
  farmSizeUnit: Yup.string().required('Farm Size Unit is required'),
  expectedHarvestDate: Yup.date().required('Enter expected harvest date'),
  expectedYield: Yup.number().required('Expected yield is required'),
  yieldUnit: Yup.string().required('Yield unit is required'),
  pricePerUnit: Yup.number().required('Price per unit is required'),
  address: Yup.string().required('Address is required'),
  state: Yup.string().required('State is required'),
  lga: Yup.string().required('LGA is required'),
  productImage: Yup.mixed().required('Product Image is required'),
  geolocation: Yup.object().shape({
    latitude: Yup.number().required('Allow location access and tap the button to get location'),
    longitude: Yup.number().required('Allow location access and tap the button to get location'),
  }).nullable(),
});

const Form = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [badImg, setBadImg] = useState({ status: false, message: '' });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [arrowToggle, setArrowToggle] = useState({
    cropPlanted: false,
    typeOfSeedling: false,
    fertilizerApplied: false,
    farmSizeUnit: false,
    yieldUnit: false,
    state: false,
    lga: false,
  });

  const formik = useFormik({
    initialValues: {
      cropPlanted: '',
      typeOfSeedling: '',
      fertilizerApplied: '',
      farmSize: '',
      farmSizeUnit: '',
      expectedHarvestDate: '',
      expectedYield: '',
      yieldUnit: '',
      pricePerUnit: '',
      address: '',
      state: '',
      lga: '',
      productImage: null,
      geolocation: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const formData = JSON.stringify(values);
      try {
        const response = await dispatch(addFarmStock(formData));
        if (response.payload) {
          setLoading(false);
          setIsSuccess(true);
        }
      } catch (error) {
        setLoading(false);
      }
    },
  });

  const convertToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxSize: 1024000,
  });

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      acceptedFiles.map(async (file) => {
        const base64Image = await convertToBase64(file);
        formik.setFieldValue('productImage', base64Image);
      });
    }

    if (fileRejections.length > 0) {
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach((error) => {
          if (error.code === 'file-too-large') {
            setBadImg({ status: true, message: `"${file.name}" is too large. Maximum size is 1MB.` });
          } else if (error.code === 'file-invalid-type') {
            setBadImg({ status: true, message: `"${file.name}" is not a valid image type. Only JPEG and PNG images are accepted.` });
          }
        });
      });
    }
  }, [acceptedFiles, fileRejections]);

  const handleArrowToggle = (field) => {
    setArrowToggle((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const { getPosition } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
    onSuccess: (position) => {
      formik.setFieldValue('geolocation', {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    },
    onError: () => {
      formik.setFieldValue('geolocation', null);
    },
  });

  const geocoderContainerRef = useRef(null);
  const geocoderRef = useRef(null);

  useEffect(() => {
    if (geocoderContainerRef.current && !geocoderRef.current) {
      geocoderRef.current = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        placeholder: 'Enter Address',
        type: 'address, postcode, place, country',
      });

      geocoderRef.current.addTo(geocoderContainerRef.current);

      geocoderRef.current.on('result', (event) => {
        const { result } = event;
        formik.setFieldValue('address', result.place_name);
      });
    }
  }, []);

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit} className={styles.container}>
        <div className={styles.formGroup}>
          <select
            name="cropPlanted"
            onChange={formik.handleChange}
            onClick={() => handleArrowToggle('cropPlanted')}
            value={formik.values.cropPlanted}
            onBlur={formik.handleBlur}
          >
            <option value="" label="Crop Planted" />
            <option value="palm oil (25 lts)" label="Palm Oil (25 litres)" />
            <option value="palm kernel" label="Palm Kernel" />
            <option value="cocoa" label="Cocoa" />
            <option value="cassava" label="Cassava" />
            <option value="rice" label="Rice" />
          </select>
          {!arrowToggle.cropPlanted ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
          {formik.errors.cropPlanted && formik.touched.cropPlanted && <div className={styles.error}>{formik.errors.cropPlanted}</div>}
        </div>

        <div className={styles.formGroup}>
          <select
            name="typeOfSeedling"
            onChange={formik.handleChange}
            onClick={() => handleArrowToggle('typeOfSeedling')}
            onBlur={formik.handleBlur}
            value={formik.values.typeOfSeedling}
          >
            <option value="" label="Type of Seeding" />
            <option value="biofortified" label="Biofortified" />
            <option value="hybrid" label="Hybrid" />
            <option value="others" label="Others" />
          </select>
          {!arrowToggle.typeOfSeedling ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
          {formik.errors.typeOfSeedling && formik.touched.typeOfSeedling && <div className={styles.error}>{formik.errors.typeOfSeedling}</div>}
        </div>

        <div className={styles.formGroup}>
          <select
            name="fertilizerApplied"
            onChange={formik.handleChange}
            onClick={() => handleArrowToggle('fertilizerApplied')}
            value={formik.values.fertilizerApplied}
            onBlur={formik.handleBlur}
          >
            <option value="" label="Fertilizer Applied" />
            <option value="organic" label="Organic" />
            <option value="inorganic" label="Inorganic" />
            <option value="none" label="None" />
          </select>
          {!arrowToggle.fertilizerApplied ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
          {formik.errors.fertilizerApplied && formik.touched.fertilizerApplied && <div className={styles.error}>{formik.errors.fertilizerApplied}</div>}
        </div>

        <div className={styles.formSplit}>
          <div className={styles.formGroup}>
            <input
              type="number"
              name="farmSize"
              onChange={formik.handleChange}
              placeholder="Farm Size"
              value={formik.values.farmSize}
              onBlur={formik.handleBlur}
            />
            {formik.errors.farmSize && formik.touched.farmSize && <div className={styles.error}>{formik.errors.farmSize}</div>}
          </div>
          <div className={styles.formGroup}>
            <select
              name="farmSizeUnit"
              onChange={formik.handleChange}
              onClick={() => handleArrowToggle('farmSizeUnit')}
              value={formik.values.farmSizeUnit}
              onBlur={formik.handleBlur}
            >
              <option value="" label="Unit" />
              <option value="sqm" label="SqM" />
              <option value="ha" label="Ha" />
              <option value="acres" label="Acres" />
            </select>
            {!arrowToggle.farmSizeUnit ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
            {formik.errors.farmSizeUnit && formik.touched.farmSizeUnit && <div className={styles.error}>{formik.errors.farmSizeUnit}</div>}
          </div>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="expectedHarvestDate">Expected Harvest Date</label>
          <input
            type="date"
            name="expectedHarvestDate"
            onChange={formik.handleChange}
            placeholder="Expected Harvest Date"
            value={formik.values.expectedHarvestDate}
            onBlur={formik.handleBlur}
          />
          {formik.errors.expectedHarvestDate && formik.touched.expectedHarvestDate && <div className={styles.error}>{formik.errors.expectedHarvestDate}</div>}
        </div>

        <div className={styles.formSplit}>
          <div className={styles.formGroup}>
            <input
              type="text"
              name="expectedYield"
              onChange={formik.handleChange}
              placeholder="Expected Yield"
              value={formik.values.expectedYield}
              onBlur={formik.handleBlur}
            />
            {formik.errors.expectedYield && formik.touched.expectedYield && <div className={styles.error}>{formik.errors.expectedYield}</div>}
          </div>
          <div className={styles.formGroup}>
            <select
              name="yieldUnit"
              onChange={formik.handleChange}
              onClick={() => handleArrowToggle('yieldUnit')}
              value={formik.values.yieldUnit}
              onBlur={formik.handleBlur}
            >
              <option value="" label="Unit" />
              <option value="Kg" label="Kilos" />
              <option value="Tonnes" label="Tonnes" />
              <option value="Tubers" label="Tubers" />
            </select>
            {!arrowToggle.yieldUnit ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
            {formik.errors.yieldUnit && formik.touched.yieldUnit && <div className={styles.error}>{formik.errors.yieldUnit}</div>}
          </div>
        </div>

        <div className={styles.formGroup}>
          <input
            type="number"
            name="pricePerUnit"
            onChange={formik.handleChange}
            placeholder="Price per Unit"
            value={formik.values.pricePerUnit}
            onBlur={formik.handleBlur}
          />
          {formik.errors.pricePerUnit && formik.touched.pricePerUnit && <div className={styles.error}>{formik.errors.pricePerUnit}</div>}
        </div>

        <div className={styles.formGroup}>
          <div {...getRootProps()} className={styles.dropzone}>
            <input {...getInputProps()} />
            {formik.values.productImage ? (
              <div className={styles.image}>
                <img src={formik.values.productImage} alt="product" />
                <p>Uploaded Image</p>
              </div>
            ) : (
              <div className={styles.image}>
                <MdImage className={styles.img} />
                <p>Upload Product Image (1mb max)</p>
              </div>
            )}
          </div>
          {formik.errors.productImage && formik.touched.productImage && <div className={styles.error}>{formik.errors.productImage}</div>}
          {badImg.status && <Alert visible={badImg.status} text={badImg.message} onClose={() => setBadImg(!badImg.status)} />}
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="address">Location of Product</label>
          <div ref={geocoderContainerRef} className={styles.geocoderContainer} />
          {formik.errors.address && formik.touched.address && <div className={styles.error}>{formik.errors.address}</div>}
        </div>

        <div className={styles.formSplit}>
          <div className={styles.formGroup}>
            <select
              name="state"
              onChange={formik.handleChange}
              onClick={() => handleArrowToggle('state')}
              value={formik.values.state}
            >
              <option value="" label="State" />
              {states.map((state) => <option key={state.state} value={state.state} label={state.state} />)}
            </select>
            {!arrowToggle.state ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
            {formik.errors.state && formik.touched.state && <div className={styles.error}>{formik.errors.state}</div>}
          </div>

          <div className={styles.formGroup}>
            <select
              name="lga"
              onChange={formik.handleChange}
              onClick={() => handleArrowToggle('lga')}
              value={formik.values.lga}
            >
              <option value="" label="LGA" />
              {states.map((state) => (state.state === formik.values.state) && state.lgas.map((lga) => <option key={lga} value={lga} label={lga} />))}
            </select>
            {!arrowToggle.lga ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
            {formik.errors.lga && formik.touched.lga && <div className={styles.error}>{formik.errors.lga}</div>}
          </div>
        </div>

        <div className={styles.formGroup}>
          <button type="button" onClick={getPosition} className={styles.card}>
            <FaMapLocationDot className={styles.map} />
            {!formik.values.geolocation && 'Get Geolocation'}
            {formik.values.geolocation && (
            <p>
              Latitude:
              {' '}
              {formik.values.geolocation.latitude}
              ,
              {' '}
              <br />
              Longitude:
              {' '}
              {formik.values.geolocation.longitude}
            </p>
            )}
          </button>
          {formik.errors.geolocation && formik.touched.geolocation && <div className={styles.error}>{formik.errors.geolocation}</div>}
        </div>

        <button type="submit" className={styles.button}>Add Product</button>
      </form>
      {isSuccess && (
        <Success
          title="Product added successfully"
          text="You can view your products in the stock section."
          link="View Stock"
          home="/palmTrack/mystock"
        />
      )}
    </>
  );
};

export default Form;
