/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import {
  signUp, signIn, verifyOTP, resendOTP, signOut, fetchStoreProducts,
} from '../reducer/authActions';

const initialState = {
  loading: false,
  isAuthenticated: '',
  user: {},
  token: null,
  error: null,
  success: false,
  phone: '',
  verified: false,
  products: [],
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    [signIn.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [signIn.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },
    [signIn.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // logout user
    [signOut.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [signOut.fulfilled]: (state) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.token = null;
      state.success = false;
    },
    [signOut.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // register user
    [signUp.pending]: (state) => {
      state.loading = true;
    },
    [signUp.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.token = payload.token;
      state.user = payload.user;
      state.phone = payload.user.phone;
    },
    [signUp.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload.data.error;
    },

    // Verify OTP
    [verifyOTP.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [verifyOTP.fulfilled]: (state) => {
      state.loading = false;
      state.verified = true;
    },
    [verifyOTP.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Resend OTP
    [resendOTP.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [resendOTP.fulfilled]: (state) => {
      state.loading = false;
      state.verified = true;
    },
    [resendOTP.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // fetch store products
    [fetchStoreProducts.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchStoreProducts.fulfilled]: (state, action) => {
      state.loading = false;
      state.products = action.payload;
      console.log(action.payload);
    },
    [fetchStoreProducts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default authSlice.reducer;
