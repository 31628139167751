/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
// /* eslint-disable arrow-body-style */
// /* eslint-disable import/no-extraneous-dependencies */
// /* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ImSpinner3 } from 'react-icons/im';
import NavB from '../../../Nav/NavB';
import VideoPlayer from '../../components/VideoPlayer';
// import { useVideoPlayback } from '../../components/context/VideoContext';
import styles from './css/Course.module.scss';
import Loader from '../../components/Loader';
import { auth } from '../../../util';

const options = {
  theme: 'light',
  position: 'bottom-right',
  autoClose: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const NextModule = () => {
  const { moduleId } = useParams();
  const { courseId } = useParams();
  const navigate = useNavigate();

  // const { isVideoEnded } = useVideoPlayback
  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState({});
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchModule = async () => {
      try {
        setIsLoading(true);
        if (!moduleId) {
          setIsError('Module not found');
          setIsLoading(false);
          return;
        }
        const response = await auth.get(`/courses/${courseId}`);

        if (response.status !== 200) {
          setIsError('Failed to fetch course');
          toast.error('Check your internet connection and try again', options);
          setIsLoading(false);
          navigate(-1);
        }

        const data = await response.data;
        // setSelectedCourse(data);
        setModules(data.modules);

        const module = await data.modules.find((mod) => mod._id === moduleId);
        setSelectedModule(module);

        setIsLoading(false);
      } catch (error) {
        setIsError(error.message);
        setIsLoading(false);
        toast.error('There seem to be some network issues', options);
        navigate(`/palmschool/courses/${courseId}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchModule();
  }, [courseId, navigate, moduleId]);

  if (isError) {
    return (
      <div>
        Error:
        {isError}
      </div>
    );
  }

  const handleAssessment = () => {
    navigate(`/palmschool/courses/${courseId}/modules/${moduleId}/assessment`);
  };

  const handleNextClick = () => {
    const currentModuleIndex = modules.findIndex((mod) => mod._id === moduleId);
    const nextModule = modules[currentModuleIndex + 1];

    if (nextModule) {
      navigate(`/palmschool/courses/${courseId}/${nextModule._id}`);
    } else {
      toast.info('This is the last module.');
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <>
          <NavB palmschool palmtrack={false} palmstore={false} palmvest={false} />
          <section className={styles.container}>
            <div className={styles.div}>
              <h1 className={styles.title}>{selectedModule.subtopic}</h1>
            </div>
            <VideoPlayer videoSrc="https://res.cloudinary.com/dn1ko8lbn/video/upload/v1723043676/Palmshops/Introduction/intro.mp4" />
            <div className={styles.actionDiv}>
              <button
                type="button"
                className={`${styles.exit} ${styles.btn}`}
                onClick={handleNextClick}
              >
                {isLoading ? <ImSpinner3 className={styles.icon} /> : 'Next'}
              </button>
              <button
                type="button"
                className={`${styles.continue} ${styles.btn}`}
                onClick={handleAssessment}
              >
                Take Assessment
              </button>
            </div>
            {isLoading && <Loader loading={isLoading} />}
          </section>
        </>
      )}
    </>
  );
};

export default NextModule;
