/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { motion, AnimatePresence } from 'framer-motion';
import { useDropzone } from 'react-dropzone';
import { MdImage } from 'react-icons/md';
import { BsCardChecklist } from 'react-icons/bs';
// import { auth } from '../../util';
import styles from './css/Order.module.scss';
import Loader from '../../PalmSchool/components/Loader';
import { fetchOrdersReq, updateOrderReq } from '../../redux/reducer/orderAction';
import Success from '../components/Success';
import Summary from '../components/Summary';
import { openSummary } from '../../redux/features/summarySlice';
import Alert from '../components/Underdev';

const options = {
  theme: 'light',
  position: 'top-right',
  autoClose: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const animateBook = {
  isHidden: { opacity: 0, x: '-100%' },
  isVisible: (transit) => ({
    opacity: 1,
    x: 0,
    transition: { delay: transit },
  }),
};

const validationSchema = Yup.object().shape({
  productImage: Yup.mixed().required('Upload your evidence of payment in jpg or png format - receipt, bank teller, etc.'),
});

const OrderList = () => {
  const { isSummaryOpen } = useSelector((state) => state.summary);
  const [loading, setLoading] = useState(true);
  const [orderId, setOrderId] = useState(null);
  const [open, setOpen] = useState(false);
  const {
    isSuccess, type, id,
  } = useSelector((state) => state?.orders);
  const [closeModal, setCloseModal] = useState(false);
  const [viewOrder, setViewOrder] = useState(null);
  const dispatch = useDispatch();
  const allOrders = useSelector((state) => state?.orders?.orders) || [];
  const successful = useSelector((state) => state?.orders?.uploaded);
  const [badImg, setBadImg] = useState({ status: false, message: '' });

  const handleCloseModal = () => {
    setOpen(false);
    setCloseModal(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (allOrders.length > 0) setLoading(false);
  }, [allOrders.length]);

  const handleCloseSummary = () => {
    setViewOrder(null);
  };

  useEffect(() => {
    if (successful && type === 'update') {
      setLoading(false);
      setOpen(false);
    } else if (!isSuccess && type === 'update') {
      setLoading(false);
      toast.error('An error occurred, please try again', options);
    }
  }, [successful, type]);

  const popDrop = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (allOrders.length === 0) {
      fetchOrdersReq(dispatch);
    }
  }, [dispatch, allOrders.length]);

  const formik = useFormik({
    initialValues: {
      productImage: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      await updateOrderReq(dispatch, orderId, JSON.stringify(values.productImage));
      if (successful) {
        formik.resetForm();
        setLoading(false);
      }
    },
  });

  const convertToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxSize: 1024000,
  });

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      acceptedFiles.map(async (file) => {
        const base64Image = await convertToBase64(file);
        formik.setFieldValue('productImage', base64Image);
      });
    }

    if (fileRejections.length > 0) {
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach((error) => {
          if (error.code === 'file-too-large') {
            setBadImg({ status: true, message: `"${file.name}" is too large. Maximum size is 1MB.` });
          } else if (error.code === 'file-invalid-type') {
            setBadImg({ status: true, message: `"${file.name}" is not a valid image type. Only JPEG and PNG images are accepted.` });
          }
        });
      });
    }
  }, [acceptedFiles, fileRejections]);

  const getDate = (order) => {
    const date = new Date(order);
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ];
    return `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()}`;
  };

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <section className={styles.stockContainer}>
      <h3 className={styles.title}>My Orders</h3>
      {(allOrders && allOrders.length > 0) ? (
        <article className={styles.cartsDiv}>
          <AnimatePresence>
            {allOrders.map((item, index) => (
              <motion.article
                key={`${item._id}-${index}`}
                variants={animateBook}
                initial="isHidden"
                animate="isVisible"
                exit="isHidden"
                layoutId={index}
                transit={(index + 1) * 0.2}
                className={styles.storeCard}
              >
                <div className={styles.orderInfo}>
                  <p className={styles.text}>
                    Order Date:
                    <span>{` ${getDate(item.createdAt)}`}</span>
                  </p>
                  <p className={styles.text}>
                    Order Number:
                    <span>{` ${item.orderNumber}`}</span>
                  </p>
                  <p className={styles.text}>
                    Status:
                    <span>{` ${item.status}`}</span>
                  </p>
                  <h4 className={styles.price}>
                    Total:
                    <span>
                      ₦
                      {item.totalAmount.toLocaleString()}
                    </span>
                  </h4>
                </div>
                <div className={styles.actionDiv}>
                  <button
                    type="button"
                    className={styles.view}
                    onClick={() => {
                      dispatch(openSummary());
                      setViewOrder(item);
                    }}
                  >
                    View Details
                  </button>
                  <motion.div className={styles.addUp}>
                    <button
                      type="button"
                      className={styles.btn}
                      disabled={item.paymentDetails || id === item.orderNumber}
                      onClick={() => {
                        setOrderId(item.orderNumber);
                        popDrop();
                      }}
                    >
                      Upload payment slip
                    </button>
                    {open && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className={`${styles.dropzone} ${(closeModal && !open) && styles.hide}`}
                        onClick={handleCloseModal}
                      >
                        <form onSubmit={formik.handleSubmit} onClick={(e) => e.stopPropagation()} className={styles.form}>
                          <div {...getRootProps()} className={styles.drop}>
                            <input {...getInputProps()} />
                            {formik.values.productImage ? (
                              <div className={styles.image}>
                                <img src={formik.values.productImage} alt="product" />
                              </div>
                            ) : (
                              <div className={styles.image}>
                                <MdImage className={styles.icon} />
                                <p>Click here (1MB max)</p>
                              </div>
                            )}
                          </div>
                          <button type="submit" className={styles.btnD}>
                            Upload payment slip
                          </button>
                          {formik.errors.productImage && formik.touched.productImage && (
                            <div className={styles.error}>
                              <span style={{ color: 'red' }}>* </span>
                              {formik.errors.productImage}
                            </div>
                          )}
                          {badImg.status && <Alert visible={badImg.status} text={badImg.message} onClose={() => setBadImg(!badImg.status)} />}
                        </form>
                      </motion.div>
                    )}
                  </motion.div>
                </div>
              </motion.article>
            ))}
          </AnimatePresence>
          {successful && <Success title="Payment Proof Sent" text="We will review the proof and a top agent will contact within 24hrs" link="Go to homepage" />}
          {isSummaryOpen && <Summary order={viewOrder} onClose={handleCloseSummary} />}
        </article>
      ) : (
        <div className={styles.empty}>
          <BsCardChecklist className={styles.icon} />
          <p className={styles.text}>No orders yet. Please visit the marketplace</p>
        </div>
      )}
    </section>
  );
};

export default OrderList;
