import React from 'react';
import styles from './Join.module.scss';

// eslint-disable-next-line react/prop-types
const Join = ({ onClick, button }) => (
  <button
    type="button"
    className={styles.button}
    onClick={onClick}
  >
    {button}
  </button>
);

export default Join;
