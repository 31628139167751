import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FiCheckCircle } from 'react-icons/fi';
import styles from './css/Success.module.scss';
import { resetSuccess } from '../../redux/features/orderSlice';
// import { resetOrder } from '../../redux/features/orderSlice';
import { closeForm } from '../../redux/features/navigationSlice';

const Success = ({
  title, text, link, home,
}) => {
  const [success, setSetSuccess] = useState(false);
  const dispatch = useDispatch();
  const hideForm = () => {
    dispatch(closeForm());
    dispatch(resetSuccess());
    setSetSuccess(true);
  };

  return (
    <div className={`${styles.container} ${success ? styles.hide : ''}`}>
      <div className={styles.success}>
        <FiCheckCircle className={styles.icon} />
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.text}>{text}</p>
        <Link to={home !== '' ? home : '/palmStore'} onClick={hideForm} className={styles.link}>{link}</Link>
      </div>
    </div>
  );
};

Success.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  home: PropTypes.string.isRequired,
};

export default Success;
