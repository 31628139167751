/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  FaAngleRight,
  FaFacebook,
  FaLinkedinIn,
} from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './DesktopAccess.module.scss';
import Join from './Pages/Navigation/Join';
import Navigation from './Pages/Navigation/Navigation';
import heroPalm from './assets/hero_palm.webp';
import mobileApp from './assets/mobile_app.png';
import footerLogo from './assets/footer_logo.png';
import About from './Pages/About/About';
import Faqs from './Pages/Faqs/Faqs';

const DesktopAccess = () => {
  useEffect(() => {
    AOS.init({
      duration: 400,
      easing: 'ease-in',
      once: true,
    });
  }, []);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/register');
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4 }}
      className={styles.container}
      style={{ overflow: 'hidden' }}
    >
      <section className={styles.homeContainer}>
        <Navigation onClick={handleNavigate} />
        <article className={styles.homeContent}>
          <div className={styles.homeText}>
            <motion.h1
              initial={{ opacity: 0, x: '-50%' }}
              animate={{ opacity: 1 }}
              whileInView={{ scaopacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
              className={styles.homeTitle}
            >
              <span className={styles.tint}>One Palm, </span>
              Many Possibilities.
            </motion.h1>
            <p className={styles.homePara}>
              <motion.span
                whileInView={{ scale: 1.2 }}
                className={styles.bold}
              >
                The WAIT IS OVER!!
                {' '}
              </motion.span>
              Our platform is designed to help you learn, build, and scale your agribusiness.
              Get started today! What are you waiting for?
            </p>
            <p className={styles.homePara}>
              Grab your phone, go to
              <Link to="/" className={styles.homeLink}> Palmshops.com </Link>
              and sign up for FREE.
              Remember, while our premium services are still FREE, they won&apos;t be for long.
            </p>
            <Join onClick={handleNavigate} button="Start Learning" />
          </div>
          <motion.img
            initial={{ opacity: 0, y: '50%' }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            src={heroPalm}
            className={styles.homeImage}
            alt="PalmShops.com hero"
          />
        </article>
      </section>
      <section className={styles.homeSection}>
        <article className={styles.homeAbout}>
          <div className={styles.homeAboutPre}>
            <motion.img
              initial={{ opacity: 0, y: '50%' }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              src={mobileApp}
              className={styles.homeAboutImage}
              alt="PalmShops.com mobile app"
              data-aos="fade-up"
            />
            <motion.h2
              initial={{ opacity: 0, y: '50%' }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className={styles.homeAboutTitle}
            >
              Palmshops is not just another agricultural platform; it’s a game-changer!
            </motion.h2>
          </div>
          <motion.div
            initial={{ opacity: 0, y: '50%' }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className={styles.homeAboutText}
          >
            <h2 className={styles.homeAboutTitle}>
              Palmshops is not just another agricultural platform; it’s a game-changer
            </h2>
            <ul className={styles.homeAboutDescription} data-aos="fade-left">
              <li className={styles.homeAboutDescriptionItem} data-aos="fade-right">
                Palmschool elevates your agribusiness knowledge with multi-lingual e-learning tools
              </li>
              <li className={styles.homeAboutDescriptionItem} data-aos="fade-right">
                Palmtrack helps manage your farm seamlessly while gathering data for traceability.
              </li>
              <li className={styles.homeAboutDescriptionItem} data-aos="fade-right">
                Palmstore gives you direct access to market and machinery at affordable prices.
              </li>
              <li className={styles.homeAboutDescriptionItem} data-aos="fade-right">
                Palmvest offers you sustainable financing options
              </li>
            </ul>
          </motion.div>
        </article>
      </section>
      <About />
      <Faqs />
      <motion.div
        initial={{ opacity: 0, x: '-20%' }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.2 }}
        className={styles.offerSection}
      >
        <div className={styles.offerContent}>
          <h3 className={styles.offerTitle}>
            Join the Moving Train of Smart Farmers
          </h3>
          <p className={styles.offerDescription}>
            Don&apos;t miss out on the opportunity to learn, build, and scale on our platform!
            Access our premium features while there are still FREE
            by signing up on your phone today.
          </p>
        </div>
        <Join onClick={handleNavigate} button="Start for free" />
      </motion.div>
      <motion.section
        initial={{ opacity: 0, width: '20%' }}
        animate={{ opacity: 1, width: '100%' }}
        transition={{ delay: 0.2 }}
        id="contact"
        className={styles.footer}
      >
        <article className={styles.footerAction}>
          <img src={footerLogo} alt="PalmShops.com hero" className={styles.footerActionImage} />
          <div className={styles.footerActionContent}>
            <h3 className={styles.footerActionTitle}>
              The Wait is Over!!!
            </h3>
            <Join onClick={handleNavigate} button="Sign up now" />
          </div>
        </article>
        <article className={styles.footerContact}>
          <div className={styles.footerContactContent}>
            <h3 className={styles.footerContactTitle}>
              Subscribe to our newsletter
            </h3>
            <div className={styles.footerContactForm}>
              <input
                type="email"
                placeholder="Email address"
                className={styles.footerContactInput}
              />
              <FaAngleRight className={styles.footerContactButton} />
            </div>
          </div>
          <small className={styles.footerContactContent}>
            <menu className={styles.footerContactMenu}>
              <li className={styles.footerItem}>
                <Link to="/" className={styles.footerLink}>
                  Home
                </Link>
              </li>
              <li className={styles.footerItem}>
                <a href="#faqs" className={styles.footerLink}>
                  FAQs
                </a>
              </li>
              <li className={styles.footerItem}>
                <a href="#about" className={styles.footerLink}>
                  About Us
                </a>
              </li>
              <li className={styles.footerItem}>
                <a href="mailto:josultgroup@gmail.com" className={styles.footerLink}>
                  Contact Us
                </a>
              </li>
            </menu>
          </small>
        </article>
        <article className={styles.footerSocial}>
          <div className={styles.footerSocialContent}>
            <h3 className={styles.footerSocialTitle}>
              Follow us on social media
            </h3>
            <ul className={styles.footerSocialLinks}>
              <li className={styles.footerSocialLinkItem}>
                <a href="https://www.linkedin.com/company/palmshops/" className={styles.footerSocialLink}>
                  <FaLinkedinIn className="icon" />
                </a>
              </li>
              <li className={styles.footerSocialLinkItem}>
                <a href="https://www.facebook.com/palmshops" className={styles.footerSocialLink}>
                  <FaFacebook className={styles.icon} />
                </a>
              </li>
            </ul>
          </div>
          <small className={styles.footerSocialContent}>
            <p className={styles.footerSocialDescription}>
              &copy; 2024 PalmShops. All rights reserved.
            </p>
          </small>
        </article>
      </motion.section>
    </motion.div>
  );
};

export default DesktopAccess;
