/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { MdStar, MdStarBorder } from 'react-icons/md';
import { addProduct } from '../../redux/features/cartSlice';
import styles from './css/ProductPage.module.scss';
import Alert from './Underdev';

const ProductPage = ({ product, deal }) => {
  const dispatch = useDispatch();
  const [buttonAdd, setButtonAdd] = useState(false);
  const [popupVisible, setIsPopupVisible] = useState(false);
  const carts = useSelector((state) => state.cart.products);
  const { type } = useSelector((state) => state.cart);

  useEffect(() => {
    const isProductInCart = carts.some((cart) => cart._id === product._id);
    setButtonAdd(isProductInCart);
  }, [carts, product]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const animatePro = {
    isHidden: { opacity: 0 },
    isVisible: {
      opacity: 1,
    },
  };

  const handleCart = async (product, e) => {
    e.stopPropagation();
    if (deal) {
      const message = `Hello, I would like to ask about a deal with ID - ${product.dealId} on your Palm Store: ${product.title} in ${product.locationStreet}, ${product.locationCity} at the rate of ${product.price}`;
      const encodedMessage = encodeURIComponent(message);
      window.open(`https://wa.me/${2348089843646}?text=${encodedMessage}`, '_blank');
    } else {
      const res = await dispatch(addProduct({
        ...product, quantity: 1, price: product.price,
      }));
      if (res.payload) {
        setIsPopupVisible(true);
      } else {
        setIsPopupVisible(false);
      }
    }
  };

  return (
    <motion.section
      className={styles.pageContainer}
      initial={{ opacity: 0.7 }}
      animate={{ opacity: 1 }}
      exit={{ transition: { duration: 0.1 } }}
    >
      <AnimatePresence initial={false}>
        <motion.section
          variants={animatePro}
          initial="isHidden"
          animate="isVisible"
          exit="isHidden"
          className={styles.section}
        >
          <div className={styles.imgContainer}>
            <img src={product.productImage} alt="product" className={deal ? `${styles.img}` : `${styles.minImg}`} />
            <p className={deal ? `${styles.priceTagD}` : `${styles.priceTag}`}>
              {deal ? `₦${product.price.toLocaleString()}/${product.time}` : `₦${product.price.toLocaleString()}`}
            </p>
          </div>
          <div className={styles.productInfo}>
            <div className={styles.productDetails}>
              <h4 className={styles.productName}>
                {product.title}
                <span>{product.metric && ` (${product.metric})`}</span>
              </h4>
              <p className={styles.productMetric}>
                {deal ? `Deal ID: ${product.dealId}` : `Product ID: ${product.productId}`}
              </p>
            </div>
            <div className={styles.productAction}>
              <p className={styles.rating}>
                {Array(5).fill(0).map((_, index) => ((
                  index < Math.round(product?.rating)) ? (
                    <MdStar
                      key={index}
                      className={styles.filledStar}
                    />
                  ) : (
                    <MdStarBorder
                      key={index}
                      className={styles.lightStar}
                    />
                  )))}
              </p>
              {!deal && (
                <button
                  type="button"
                  className={styles.btn}
                  onClick={(e) => handleCart(product, e)}
                  disabled={buttonAdd}
                >
                  {!buttonAdd ? 'Add to cart' : 'Added to cart'}
                </button>
              )}
              {
                deal && (
                  <button
                    type="button"
                    className={styles.btn}
                    onClick={(e) => handleCart(product, e)}
                    disabled={buttonAdd}
                  >
                    Talk to Ini
                  </button>
                )
              }
            </div>
          </div>
          <div className={styles.desc}>
            <h3 className={styles.subheading}>Product Details</h3>
            <p className={styles.text}>{product.description}</p>
            {deal && (
              <p className={styles.text}>
                <span>Location </span>
                {`${product.locationStreet}, (${product.locationCity})`}
              </p>
            )}
          </div>
        </motion.section>
      </AnimatePresence>
      {popupVisible && <Alert visible={popupVisible} text={type} onClose={() => setIsPopupVisible(false)} /> }
    </motion.section>
  );
};

ProductPage.propTypes = {
  product: PropTypes.object.isRequired,
  deal: PropTypes.bool.isRequired,
};

export default ProductPage;
