/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useState, useEffect,
} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { AiFillCloseCircle } from 'react-icons/ai';
import { BsArrowUpCircleFill } from 'react-icons/bs';
import { MdArrowOutward } from 'react-icons/md';
import { Link, Outlet } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import styles from './Navigation.module.scss';
import menu from '../../assets/menu.png';
import palmshops from '../../assets/palmshops.svg';
/* eslint-disable react/jsx-boolean-value */

// eslint-disable-next-line react/prop-types
const Navigation = ({ onClick }) => {
  useEffect(() => {
    AOS.init({
      duration: 400,
      easing: 'ease-in',
      once: true,
    });
  }, []);

  const [toggle, setToggle] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  const handleScroll = () => {
    const scrolled = window.scrollY > window.innerHeight;
    setHasScrolled(scrolled);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuOpen = () => {
    setToggle(!toggle);
  };

  const handleMenuClose = () => {
    setToggle(false);
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    const sectionRefs = {
      home: '',
      about: '',
      faqs: '',
      contact: '',
    };

    const sectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 },
    );

    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) {
        sectionObserver.observe(ref.current);
      }
    });

    return () => {
      sectionObserver.disconnect();
    };
  }, []);

  const handleNavigationClick = (section) => {
    setActiveSection(section);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header className={`${styles.header} ${hasScrolled ? styles.scrolled : ''}`}>
      <nav className={styles.navBar}>
        <div className={styles.navPre}>
          {
          toggle ? (
            <AiFillCloseCircle onClick={handleMenuClose} className={styles.exitMenu} />
          ) : (
            <img
              src={menu}
              alt="menu icon"
              onClick={handleMenuOpen}
              className={styles.mobileMenuIcon}
            />
          )
        }

          <Link to="/" className={styles.navLink}>
            <img
              src={palmshops}
              alt="PalmShops.com logo"
              className={styles.navLogo}
            />
          </Link>
        </div>
        {toggle && (
          <div className={styles.navLinksMob}>
            <ul className={styles.navList}>
              <li className={styles.navListItem}>
                <Link to="/" className={styles.navLinkItem} data-aos="fade-up" onClick={handleMenuClose}>
                  Home
                </Link>
              </li>
              <li className={styles.navListItem}>
                <a href="#about" className={styles.navLinkItem} data-aos="fade-up" onClick={handleMenuClose}>
                  About
                </a>
              </li>
              <li className={styles.navListItem}>
                <a href="#faqs" className={styles.navLinkItem} data-aos="fade-up" onClick={handleMenuClose}>
                  FAQs
                </a>
              </li>
              <li className={styles.navListItem}>
                <a href="#contact" className={styles.navLinkItem} data-aos="fade-up" onClick={handleMenuClose}>
                  Contact
                </a>
              </li>
            </ul>
          </div>
        )}
        <div className={styles.navLinks}>
          <ul className={styles.navList}>
            <li className={`${styles.navListItem} ${activeSection === 'home' ? 'active' : ''}`} onClick={() => handleNavigationClick('home')}>
              <Link to="/" className={styles.navLinkItem} data-aos="fade-up" onClick={handleMenuClose}>
                Home
              </Link>
            </li>
            <li className={`${styles.navListItem} ${activeSection === 'about' ? 'active' : ''}`} onClick={() => handleNavigationClick('about')}>
              <a href="#about" className={styles.navLinkItem} data-aos="fade-up" onClick={handleMenuClose}>
                About
              </a>
            </li>
            <li className={`${styles.navListItem} ${activeSection === 'faqs' ? 'active' : ''}`} onClick={() => handleNavigationClick('faqs')}>
              <a href="#faqs" className={styles.navLinkItem} data-aos="fade-up" onClick={handleMenuClose}>
                FAQs
              </a>
            </li>
            <li className={`${styles.navListItem} ${activeSection === 'contact' ? 'active' : ''}`} onClick={() => handleNavigationClick('contact')}>
              <a href="#contact" className={styles.navLinkItem} data-aos="fade-up" onClick={handleMenuClose}>
                Contact
              </a>
            </li>
          </ul>
        </div>
        <button
          type="button"
          className={styles.button}
          onClick={onClick}
        >
          Sign Up Now
          {windowWidth > 768 ? <MdArrowOutward className={styles.arrow} /> : null}
        </button>
        <BsArrowUpCircleFill onClick={scrollToTop} className={styles.scroll} />
      </nav>

      <Outlet />
    </header>
  );
};

export default Navigation;
