/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchNotication, markAsRead, markAllAsRead, deleteNotif, clearNotif,
} from '../reducer/notifAction';

const notifications = localStorage.getItem('notification');

const initialState = {
  loading: false,
  notifications: notifications || [],
  error: null,
};

const notifSlice = createSlice({
  name: 'notif',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchNotication.pending]: (state) => {
      state.loading = true;
    },
    [fetchNotication.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.notifications.unshift(payload);
      state.success = true;
    },
    [fetchNotication.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    [markAsRead.pending]: (state) => {
      state.loading = true;
    },
    [markAsRead.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const notification = state.notifications.find((notif) => notif._id === payload);
      if (notification) {
        notification.isRead = true;
      }
    },
    [markAllAsRead.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // Delete Farm Stock
    [markAllAsRead.pending]: (state) => {
      state.loading = true;
    },
    [markAllAsRead.fulfilled]: (state) => {
      state.loading = false;
      state.notifications.forEach((notif) => {
        notif.isRead = true;
      });
    },
    [markAllAsRead.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // Get Farm Stock
    [deleteNotif.pending]: (state) => {
      state.loading = true;
    },
    [deleteNotif.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.notifications = state.notifications.filter((notif) => notif._id !== payload);
      state.success = true;
    },
    [deleteNotif.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // Clear Farm Stock
    [clearNotif.pending]: (state) => {
      state.loading = true;
    },
    [clearNotif.fulfilled]: (state) => {
      state.loading = false;
      state.notifications = [];
    },
    [clearNotif.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default notifSlice.reducer;
