/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import Aos from 'aos';
import { motion } from 'framer-motion';
import styles from './css/Palm.module.scss';
import hero from '../images/hero.svg';

const Palm = () => {
  useEffect(() => {
    Aos.init({
      duration: 400,
      easing: 'ease-in',
      once: true,
    });
  }, []);
  return (
    <motion.section
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={styles.homeSection}
      style={{ overflow: 'hidden' }}
    >
      <motion.article className={styles.homeAbout}>
        <motion.div
          className={styles.homeAboutPre}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <motion.img
            initial={{ opacity: 0, width: 0 }}
            animate={{ opacity: 1, width: '100%' }}
            transition={{ duration: 0.5 }}
            whileInView={{ scale: 1.05, transition: { duration: 0.3 } }}
            src={hero}
            className={styles.homeAboutImage}
            alt="PalmShops.com mobile app"
          />
          <h2 className={styles.homeAboutTitle}>
            PALMSHOPS is not just another agricultural platform; it’s a game-changer
          </h2>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: '100%' }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3 }}
          className={styles.homeAboutText}
        >
          <h2 className={styles.homeAboutTitle}>
            Palmshops is not just another agricultural platform; it’s a game-changer
          </h2>
          <ul className={styles.homeAboutDescription} data-aos="fade-left">
            <li className={styles.homeAboutDescriptionItem} data-aos="zoom-up">
              Palmschool elevates your agribusiness knowledge with multi-lingual e-learning tools
            </li>
            <li className={styles.homeAboutDescriptionItem} data-aos="zoom-up">
              Palmtrack helps manage your farm seamlessly while gathering data for traceability.
            </li>
            <li className={styles.homeAboutDescriptionItem} data-aos="zoom-up">
              Palmstore gives you direct access to market and machinery at affordable prices.
            </li>
            <li className={styles.homeAboutDescriptionItem} data-aos="zoom-up">
              Palmvest offers you sustainable financing options
            </li>
          </ul>
        </motion.div>
      </motion.article>
    </motion.section>
  );
};

export default Palm;
