/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  Link, Outlet, useNavigate, useLocation,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FaRegUserCircle } from 'react-icons/fa';
import { MdKeyboardBackspace, MdOutlineShoppingCart, MdOutlineInventory } from 'react-icons/md';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { CiSearch } from 'react-icons/ci';
import styles from './Nav.module.scss';
import palmshops from '../PalmSchool/images/logo/logo.svg';
import { signOut } from '../redux/reducer/authActions';
import Loader from '../PalmSchool/components/Loader';
import { goBack, closeForm, navigateTo } from '../redux/features/navigationSlice';
import { removeProduct } from '../redux/reducer/productAction';
import { closeSummary } from '../redux/features/summarySlice';

const NavB = ({
  palmschool, palmtrack, palmstore, palmvest,
}) => {
  useEffect(() => {
    AOS.init({
      duration: 400,
      easing: 'ease-in',
      once: true,
    });
  }, []);

  // useSelector((state) => state.notif.notifications);

  const notif = [
    // {
    //   id: 1,
    //   title: 'New Course Available',
    //   message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    //   isRead: false,
    // },
    // {
    //   id: 2,
    //   title: 'New Course Available',
    //   message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    //   isRead: false,
    // },
    // {
    //   id: 3,
    //   title: 'New Course Available',
    //   message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    //   isRead: false,
    // },
    // {
    //   id: 4,
    //   title: 'New Course Available',
    //   message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    //   isRead: true,
    // },
  ];
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const searchRef = useRef(null);
  const iconRef = useRef(null);
  const timeoutRef = useRef(null);
  const dispatch = useDispatch();
  const { selectedProduct } = useSelector((state) => state.product);
  const { loading, success } = useSelector((state) => state.auth);
  const [isShowing, setIsShowing] = useState(false);

  const cartCount = useSelector((state) => state.cart.products?.length);
  const isSummaryOpen = useSelector((state) => state.summary.isSummaryOpen);
  const isOpenForm = useSelector((state) => state.navigation.isOpenForm);
  const location = useLocation();

  useEffect(() => {
    const handleBackNavigation = () => {
      const currentPath = location.pathname;

      if (['/palmStore', '/palmTrack', '/palmSchool'].includes(currentPath)) {
        navigate('/');
      } else {
        window.history.back();
      }
    };

    window.addEventListener('popstate', handleBackNavigation);
    return () => {
      window.removeEventListener('popstate', handleBackNavigation);
    };
  }, [location, navigate]);

  const closeSearchTray = () => {
    setToggle(false);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const selectCart = () => {
    dispatch(navigateTo('Cart'));
  };

  const handleBackClick = (e) => {
    const lastVisited = localStorage.getItem('lastVisited') || '/';
    e.preventDefault();
    if (selectedProduct) {
      dispatch(removeProduct());
      return;
    }

    if (isSummaryOpen) {
      dispatch(closeSummary());
      return;
    }

    if (isOpenForm) {
      dispatch(closeForm());
      return;
    }

    if (palmstore) {
      dispatch(goBack());
    }

    if (lastVisited && lastVisited !== window.location.pathname) {
      navigate(lastVisited);
    } else {
      navigate(-1);
    }
  };

  const toggleSearch = (e) => {
    e.stopPropagation();
    if (!toggle) {
      setToggle(true);
      timeoutRef.current = setTimeout(closeSearchTray, 40000);
    } else {
      closeSearchTray();
    }
  };

  const submitSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (searchQuery === '') {
      setToggle(false);
      return;
    }
    navigate(`/courses/search?query=${encodeURIComponent(searchQuery)}`);
  };

  const showMenu = () => {
    setIsShowing(!isShowing);
  };

  const handleSignOut = () => {
    dispatch(signOut());
    if (success) {
      navigate('/');
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (searchRef.current && !searchRef.current?.contains(e.target) && !iconRef.current?.contains(e.target)) {
        closeSearchTray();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className={styles.scroller}>
      <nav className={styles.navBar}>
        <div className={styles.navPre}>
          <MdKeyboardBackspace className={styles.arrowIcon} onClick={handleBackClick} />
        </div>
        <Link to="/" className={styles.navLinkB}>
          <img
            src={palmshops}
            alt="PalmShops.com logo"
            className={styles.navLogoB}
          />
        </Link>
        <div className={styles.profile}>
          {palmstore && (
            <>
              <MdOutlineShoppingCart className={styles.icon} onClick={selectCart} />
              {cartCount > 0 && <span className={styles.cartCount} />}
            </>
          )}
          {!palmschool && (palmstore || palmtrack || palmvest) && (
            <div className={styles.note}>
              <IoIosNotificationsOutline className={styles.icon} />
              {(notif && notif.map((no) => !no.isRead)) && <span className={styles.notif} />}
            </div>
          )}
          {palmschool && (
            <>
              <div className={styles.search}>
                <CiSearch className={styles.icon} onClick={toggleSearch} ref={iconRef} />
                {toggle && (
                  <motion.form
                    initial={{ x: 100, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: 100, opacity: 0.3, transition: { duration: 0.3 } }}
                    transition={{ duration: 0.1 }}
                    className={styles.navSearch}
                    onSubmit={submitSearch}
                    ref={searchRef}
                  >
                    <input
                      type="text"
                      placeholder="Search Topic"
                      className={styles.searchInput}
                      value={searchQuery}
                      onChange={(e) => { e.stopPropagation(); setSearchQuery(e.target.value); }}
                    />
                    <button type="submit" className={styles.searchBtn}>
                      <CiSearch className={styles.iconic} />
                    </button>
                  </motion.form>
                )}
              </div>
              <Link to="#" className={styles.profileImg} onClick={showMenu}>
                <FaRegUserCircle className={styles.user} />
              </Link>
              {isShowing && (
                <article className={styles.action}>
                  <button type="button" onClick={handleSignOut} className={styles.logout}>
                    Log out
                  </button>
                </article>
              )}
            </>
          )}
          {palmtrack && <MdOutlineInventory className={styles.icon} onClick={() => navigate('/palmTrack/mystock')} />}
        </div>
        {loading && <Loader loading={loading} />}
      </nav>

      <Outlet />
    </header>
  );
};

NavB.propTypes = {
  palmtrack: PropTypes.bool.isRequired,
  palmschool: PropTypes.bool.isRequired,
  palmstore: PropTypes.bool.isRequired,
  palmvest: PropTypes.bool.isRequired,
};

export default NavB;
