/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './css/Success.module.scss';

const Alert = ({ text, visible, onClose }) => {
  const [closeModal, setCloseModal] = useState(!visible);

  useEffect(() => {
    if (visible) {
      setCloseModal(false);
      setTimeout(() => {
        setCloseModal(true);
        onClose();
      }, 2000);
    }
  }, [visible]);

  const handleCloseModal = () => {
    setCloseModal(true);
    onClose();
  };

  return (
    <div className={`${styles.container} ${closeModal && styles.hide}`} onClick={handleCloseModal}>
      <article className={styles.article}>
        <p className={styles.text}>{text}</p>
      </article>
    </div>
  );
};

Alert.propTypes = {
  text: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Alert;
