/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import {
  addFarmStock, deleteFarmStock, updateFarmStock, getFarmStock,
} from '../reducer/farmAction';

const initialState = {
  loading: false,
  farms: [],
  error: null,
  success: false,
};

const farmSlice = createSlice({
  name: 'farm',
  initialState,
  reducers: {},
  extraReducers: {
    [addFarmStock.pending]: (state) => {
      state.loading = true;
    },
    [addFarmStock.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.farms = [...state.farms, payload];
      state.success = true;
    },
    [addFarmStock.rejected]: (state) => {
      state.loading = false;
    },

    // Update Farm Stock
    [updateFarmStock.pending]: (state) => {
      state.loading = true;
    },
    [updateFarmStock.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.farms = state.farms.map((product) => (product._id === payload._id ? payload : product));
      state.success = true;
    },
    [updateFarmStock.rejected]: (state) => {
      state.loading = false;
    },

    // Delete Farm Stock
    [deleteFarmStock.pending]: (state) => {
      state.loading = true;
    },
    [deleteFarmStock.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.farms = state.farms.filter((product) => product._id !== payload.id);
      state.success = true;
    },
    [deleteFarmStock.rejected]: (state) => {
      state.loading = false;
    },

    // Get Farm Stock
    [getFarmStock.pending]: (state) => {
      state.loading = true;
    },
    [getFarmStock.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.farms = payload;
      state.success = true;
    },
    [getFarmStock.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default farmSlice.reducer;
